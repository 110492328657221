<template>
    <modal-component :show="show" @close="close">
        <template #title> Бонусы </template>
        <template #content>
            <h3 class="h3 text-info">Голосуйте на мониторингах</h3>

            <p class="text-info">
                Голосуя за наш проект на мониторингах, Вы не только продвигаете
                его вверх, но и получаете на счет бонусы, которые можно
                бесплатно обменять на кейсы на любом сервере введя команду
                /bonus use.
            </p>

            <p class="text-info">
                Мы благодарны за отдачу, которая идет от Вас, и дополнительно
                раз в месяц топ-10 по количеству голосов на мониторингах
                получают дорогие призы. Отслеживать свой рейтинг можно в разделе
                &laquo;Голосование&raquo;.
            </p>
        </template>
        <template #close>
            <button class="modal__close" type="button" @click="close()">
                <close-modal-icon />
            </button>
        </template>
    </modal-component>
</template>

<script>
import ModalComponent from '@/Components/Reusable/ModalComponent.vue';
import CloseModalIcon from '@/Components/Reusable/CloseModalIcon.vue';

export default {
    name: 'LoginModalComponent',
    components: {
        ModalComponent,
        CloseModalIcon,
    },
    props: ['show'],
    emits: ['close'],
    data() {
        return {};
    },
    methods: {
        close() {
            this.$emit('close');
        },
    },
};
</script>
