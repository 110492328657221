<template>
    <div v-if="links.length > 3" class="pagination">
        <template v-for="(link, key) in links" :key="key">
            <div
                v-if="link.url === null"
                class="pagination__label"
                v-html="link.label" />
            <Link
                v-else
                :class="{'pagination__link--active': link.active}"
                :href="link.url"
                class="pagination__link"
                preserve-scroll
                v-html="link.label" />
        </template>

        <div v-if="market" class="pagination-count">
            Всего позиций на рынке: <b v-html="count"></b>
        </div>
    </div>
</template>

<script>
import {Link} from '@inertiajs/vue3';

export default {
    components: {
        Link,
    },
    props: {
        links: Array,
        market: Boolean,
        count: Number,
    },
};
</script>
