<template>
    <app-layout title="2FA авторизация">
        <cards-component></cards-component>

        <modal-component :show="true" @close="close">
            <template #title> Подтвердите вход в аккаунт </template>

            <template #content>
                <div class="mb-16">
                    <template v-if="!recovery">
                        Пожалуйста, подтвердите доступ к вашей учетной записи,
                        введя код аутентификации, предоставленный вашим
                        аутентификатор приложение.
                    </template>

                    <template v-else>
                        Пожалуйста, подтвердите доступ к вашей учетной записи,
                        введя один из ваших кодов экстренного восстановления.
                    </template>
                </div>

                <form @submit.prevent="submit">
                    <div v-if="!recovery" class="input-group mb-16">
                        <jet-label for="code" value="Код из приложения" />
                        <jet-input
                            id="code"
                            ref="code"
                            v-model="form.code"
                            class="input-login"
                            placeholder="Введите код из приложения"
                            autocomplete="one-time-code"
                            inputmode="numeric"
                            autofocus
                            type="text" />
                    </div>

                    <div v-else class="input-group mb-16">
                        <jet-label
                            for="recovery_code"
                            value="Код восстановления" />
                        <jet-input
                            id="recovery_code"
                            ref="recovery_code"
                            v-model="form.recovery_code"
                            class="input-login"
                            placeholder="Введите код восстановления"
                            autocomplete="one-time-code"
                            type="text" />
                    </div>

                    <div class="cabinet__qr-login--buttons">
                        <jet-button
                            :class="{'opacity-25': form.processing}"
                            :disabled="form.processing">
                            Войти
                        </jet-button>

                        <button
                            class="button button--small button--secondary text-dark"
                            type="button"
                            @click.prevent="toggleRecovery">
                            <template v-if="!recovery">
                                Использовать код восстановления
                            </template>

                            <template v-else>
                                Использовать код аутентификации
                            </template>
                        </button>
                    </div>
                </form>
            </template>
        </modal-component>
    </app-layout>
</template>

<script>
import JetAuthenticationCard from '@/Jetstream/AuthenticationCard.vue';
import JetAuthenticationCardLogo from '@/Jetstream/AuthenticationCardLogo.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetLabel from '@/Jetstream/Label.vue';
import JetValidationErrors from '@/Jetstream/ValidationErrors.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import ModalComponent from '../../Components/Reusable/ModalComponent.vue';
import CardsComponent from '@/Components/Home/CardsComponent.vue';
import MonitoringComponent from '@/Components/Home/MonitoringComponent.vue';
import ZombieApocalypseComponent from '@/Components/Home/ZombieApocalypseComponent.vue';
import GoVoteComponent from '@/Components/GoVoteComponent.vue';
import {router} from '@inertiajs/vue3';

export default {
    components: {
        ModalComponent,
        AppLayout,
        JetAuthenticationCard,
        JetAuthenticationCardLogo,
        JetButton,
        JetInput,
        JetLabel,
        JetValidationErrors,
        CardsComponent,
        MonitoringComponent,
        ZombieApocalypseComponent,
        GoVoteComponent,
    },

    data() {
        return {
            recovery: false,
            form: this.$inertia.form({
                code: '',
                recovery_code: '',
            }),
        };
    },

    methods: {
        toggleRecovery() {
            this.recovery ^= true;

            this.$nextTick(() => {
                if (this.recovery) {
                    this.$refs.recovery_code.focus();
                    this.form.code = '';
                } else {
                    this.$refs.code.focus();
                    this.form.recovery_code = '';
                }
            });
        },

        close() {
            router.visit('/');
        },

        submit() {
            this.form.post(this.route('two-factor.login'), {
                onBefore: () => (this.$page.props.errors = {}),
            });
        },
    },
};
</script>
