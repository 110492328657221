<template>
    <section class="top-votes">
        <div class="container top-votes__container">
            <h3 class="text-title text-dark text-normal text-center">Топы</h3>

            <div class="top-votes__table">
                <div class="top-votes__table--left">
                    <h4 class="top-votes__table--title">Топ голосований</h4>
                    <div
                        v-for="user in getVotes"
                        :key="user.user_id"
                        :class="{
                            active:
                                $page.props.user &&
                                user.user_id === $page.props.user.id,
                        }"
                        class="top-votes__table--item">
                        <Link
                            :href="
                                user?.user?.name
                                    ? route('profile.view', user.user.name)
                                    : '#'
                            "
                            class="link">
                            <img
                                :alt="user.user?.name"
                                :src="'/head/' + user.user?.name" />
                        </Link>

                        <div class="top-votes__table--item-info">
                            <span
                                class="name"
                                v-text="user.user?.name ?? 'Unknown'" />
                            Проголосовал(-а) {{ user.votes }}
                            {{ declOfNum(user.votes, ['раз', 'раза', 'раз']) }}
                        </div>
                    </div>

                    <template v-if="isEmpty(mainVotes)">
                        <div
                            v-for="i in 5"
                            :key="i"
                            class="top-votes__table--item">
                            <img
                                alt="Default"
                                src="/head/default"
                                class="none" />
                            <div class="top-votes__table--item-info">
                                <span class="name">
                                    В этом месяце никто не голосовал
                                </span>
                                Будь первым!
                            </div>
                        </div>
                    </template>
                </div>

                <div class="top-votes__table--right">
                    <h4 class="top-votes__table--title">Топ богачей</h4>
                    <template v-if="mainMoney && mainMoney.length !== 0">
                        <div
                            v-for="user in mainMoney"
                            :key="user.id"
                            class="top-votes__table--item"
                            :class="{
                                active:
                                    $page.props.user &&
                                    user.id === $page.props.user.id,
                            }">
                            <Link
                                :href="route('profile.view', user.name)"
                                class="link">
                                <img
                                    :alt="user.name"
                                    :src="'/head/' + user.name" />
                            </Link>

                            <div class="top-votes__table--item-info">
                                <span class="name" v-text="user.name" />
                                {{ user.money }} ₽
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {declOfNum} from '@/Methods/declOfNum.js';
import {Link} from '@inertiajs/vue3';
import {isEmpty} from 'lodash';

export default {
    name: 'TopVotesComponent',
    components: {Link},
    props: {
        mainVotes: Array,
        mainMoney: Array,
    },

    computed: {
        getVotes() {
            if (isEmpty(this.mainVotes)) {
                return [];
            }

            return this.mainVotes;
        },
    },
    methods: {
        isEmpty,
        declOfNum: declOfNum,
    },
};
</script>
