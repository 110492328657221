<template>
    <div
        :class="{
            container: true,
            menu__container: true,
            footer__container: footer,
        }"
        data-adaptive="desktop">
        <ul class="menu__list" data-placement="before_logo">
            <li
                v-for="link in links.filter(
                    item => item.placement === 'before_logo',
                )"
                :class="{active: $page.url === link.oldPage}"
                class="menu__link">
                <button
                    v-if="link.launcher"
                    class="link"
                    @click="showLauncherModal = true">
                    {{ link.title }}
                </button>
                <a
                    v-else-if="link.external"
                    :href="link.route"
                    class="link"
                    target="_blank">
                    {{ link.title }}
                </a>
                <Link v-else :href="link.route" class="link">{{
                    link.title
                }}</Link>
            </li>
        </ul>
        <a class="menu__logo image" href="/">
            <logo-component />
        </a>
        <ul class="menu__list" data-placement="after_logo">
            <li
                v-for="link in links.filter(
                    item => item.placement === 'after_logo',
                )"
                :class="{active: $page.url === link.oldPage}"
                class="menu__link">
                <button
                    v-if="link.launcher"
                    class="link"
                    @click="showLauncherModal = true">
                    {{ link.title }}
                </button>
                <a
                    v-else-if="link.external"
                    :href="link.route"
                    class="link"
                    target="_blank">
                    {{ link.title }}
                </a>
                <Link v-else :href="link.route" class="link">{{
                    link.title
                }}</Link>
            </li>
        </ul>
    </div>
    <div
        v-if="!footer"
        class="container menu__container"
        data-adaptive="mobile">
        <a
            :data-open="isMenuOpened"
            class="menu__open"
            tabindex="0"
            @click="openMenu()"
            @keydown.esc="closeMenu()">
            <svg
                fill="none"
                height="36"
                viewBox="0 0 36 36"
                width="36"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M31.5 27H13.5V24H31.5V27ZM31.5 19.5H4.5V16.5H31.5V19.5ZM31.5 12H13.5V9H31.5V12Z"
                    fill="white" />
            </svg>
        </a>
        <teleport to="body">
            <div
                :data-open="isMenuOpened"
                class="menu__mobile"
                tabindex="0"
                @keydown.esc="closeMenu()">
                <div class="menu__mobile--container">
                    <div class="menu__close">
                        <button @click="closeMenu()">
                            <svg
                                fill="none"
                                height="36"
                                viewBox="0 0 36 36"
                                width="36"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M26.385 7.5L18 15.885L9.615 7.5L7.5 9.615L15.885 18L7.5 26.385L9.615 28.5L18 20.115L26.385 28.5L28.5 26.385L20.115 18L28.5 9.615L26.385 7.5Z"
                                    fill="#2C3147" />
                            </svg>
                        </button>
                    </div>
                    <ul class="menu__mobile--list">
                        <li
                            v-for="link in links"
                            :class="{active: $page.url === link.oldPage}"
                            class="menu__mobile--item">
                            <button
                                v-if="link.launcher"
                                class="link"
                                @click="
                                    closeMenu();
                                    showLauncherModal = true;
                                ">
                                {{ link.title }}
                            </button>
                            <a
                                v-else-if="link.external"
                                :href="link.route"
                                class="link"
                                target="_blank">
                                {{ link.title }}
                            </a>
                            <Link
                                v-else
                                :href="link.route"
                                class="link"
                                @click="closeMenu()">
                                {{ link.title }}
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </teleport>
    </div>

    <launcher-modal-component
        :show="showLauncherModal"
        @close="showLauncherModal = false"></launcher-modal-component>
</template>

<script>
import LogoComponent from './LogoComponent.vue';
import {Link} from '@inertiajs/vue3';
import LauncherModalComponent from './Modals/LauncherModalComponent.vue';

export default {
    name: 'MenuComponent',
    components: {
        LauncherModalComponent,
        Link,
        LogoComponent,
    },
    props: {
        footer: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isMenuOpened: false,
            showLauncherModal: false,
            links: [
                {
                    title: 'Главная',
                    oldPage: '/',
                    route: this.route('home'),
                    placement: 'before_logo',
                    external: false,
                },
                {
                    title: 'Форум',
                    oldPage: '',
                    route: 'https://forum.hardmine.ru/',
                    placement: 'before_logo',
                    external: true,
                },
                {
                    title: 'Бан-лист',
                    oldPage: '/page/bans',
                    route: this.route('bans'),
                    placement: 'before_logo',
                    external: false,
                },
                {
                    title: 'Голосование',
                    oldPage: '/page/vote',
                    route: this.route('vote'),
                    placement: 'before_logo',
                    external: false,
                },
                {
                    title: 'Лаунчер',
                    oldPage: '',
                    route: this.route('home'),
                    placement: 'after_logo',
                    external: false,
                    launcher: true,
                },
                {
                    title: 'Личный кабинет',
                    oldPage: '/cabinet',
                    route: this.route('cabinet'),
                    placement: 'after_logo',
                    external: false,
                },
                {
                    title: 'Рынок',
                    oldPage: '/market',
                    route: this.route('market.index'),
                    placement: 'after_logo',
                    external: false,
                },
                {
                    title: 'Донат',
                    oldPage: '/page/donate',
                    route: this.route('donate'),
                    placement: 'after_logo',
                    external: false,
                },
            ],
        };
    },

    mounted() {
        if (this.$page.props.flash.showLauncherModal) {
            this.showLauncherModal = true;
        }
    },

    methods: {
        closeMenu() {
            document.body.classList.remove('overflow-hidden');
            this.isMenuOpened = false;
        },
        openMenu() {
            document.body.classList.add('overflow-hidden');
            this.isMenuOpened = true;
        },
    },
};
</script>
