<template>
    <div class="donate__server">
        <h2 class="text-title">Покупка привилегий</h2>
        <h3 class="text-subtitle" v-html="server?.name"></h3>

        <div class="donate__server--buttons__wrapper">
            <div class="donate__server--buttons">
                <button
                    v-for="(server, index) in servers"
                    :key="index"
                    class="button button--select button--blue button--small"
                    :class="{'button--opacity': server.slug === slug}"
                    @click.prevent="slug = server.slug"
                    v-html="server.name"></button>
            </div>
        </div>

        <!--<select id="server" v-model="slug" class="input input-server input-select" required>
            <option :selected="!slug" :disabled="slug" value="">Выберите сервер</option>
            <option v-for="(server,index) in servers" :key="index" :value="server.slug"
                    v-html="server.name"></option>
        </select>-->
    </div>
</template>

<script>
import {stringify} from 'qs';
import debounce from 'lodash/debounce';
import {router} from '@inertiajs/vue3';

export default {
    name: 'Select',
    emits: ['serverChanged'],
    data() {
        return {
            server: null,
            slug: null,
            servers: Array,
        };
    },
    mounted() {
        this.servers = this.$page.props.servers;
        this.slug = this.$page.props.initialServer;

        // if (this.slug !== null)
        //     this.submit();
    },
    methods: {
        submit() {
            const query = stringify({
                server: this.slug || undefined,
            });

            router.visit(
                query
                    ? `${this.route('donate')}?${query}`
                    : `${this.route('donate')}`,
                {
                    preserveScroll: true,
                    preserveState: true,
                    // only: ['donate']
                },
            );

            this.server = this.servers.filter(
                item => item.slug === this.slug,
            )[0];
            this.$emit('serverChanged', this.slug);
        },
    },
    watch: {
        slug: debounce(function () {
            this.submit();
        }, 0),
    },
};
</script>
