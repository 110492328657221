<template>
    <modal-component :show="show" @close="close">
        <template #title>
            {{ server.name }}
        </template>
        <template #content>
            <form class="modal-donate" @submit.prevent="submit()">
                <notify-component
                    v-if="error"
                    :message="message"
                    type="error" />

                <h2>Некоторые возможности [{{ product.group.name }}]</h2>
                <p v-html="product.group.description"></p>

                <div class="modal-donate__types">
                    <div
                        v-for="(item, key) in JSON.parse(product.group.types)"
                        :key="key"
                        :class="{active: form.type_id === key}"
                        class="modal-donate__types--item"
                        @click.prevent="form.type_id = key">
                        <span v-if="item.days > 0">{{ item.days }} дней</span>
                        <span v-else>Навсегда</span>
                        <div class="modal-donate__types--item-prices">
                            <span :class="{oldPrice: isDisc(item)}">
                                {{ item.price }} ₽
                            </span>
                            <span v-if="isDisc(item)" class="newPrice">
                                {{ item.newPrice }} ₽
                            </span>
                        </div>
                    </div>
                </div>

                <div class="modal-donate__button">
                    <button class="button button--blue" type="submit">
                        Купить группу
                    </button>
                </div>
            </form>
        </template>
        <template #close>
            <button class="modal__close" type="button" @click="close()">
                <close-modal-icon />
            </button>
        </template>
    </modal-component>
</template>

<script>
import ModalComponent from '@/Components/Reusable/ModalComponent.vue';
import TooltipComponent from '../Reusable/TooltipComponent.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import JetLabel from '@/Jetstream/Label.vue';
import AlertComponent from '@/Components/Reusable/AlertComponent.vue';
import CloseModalIcon from '@/Components/Reusable/CloseModalIcon.vue';
import NotifyComponent from '@/Components/Reusable/NotifyComponent.vue';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';

export default {
    name: 'DonateModalComponent',
    components: {
        NotifyComponent,
        AlertComponent,
        ModalComponent,
        JetButton,
        JetInput,
        JetCheckbox,
        JetLabel,
        CloseModalIcon,
        TooltipComponent,
    },
    props: ['show', 'product', 'server'],
    emits: ['close', 'update'],
    data() {
        return {
            form: this.$inertia.form({
                server_id: null,
                group_id: null,
                type_id: 0,
            }),
            error: false,
            message: '',
        };
    },

    methods: {
        close() {
            this.$emit('close');
        },

        formatDate(date) {
            return moment(date).format('DD.MM.YYYY HH:mm');
        },

        isDisc(item) {
            if (
                item.newPrice &&
                item.newPrice > 0 &&
                item.newPrice < item.price
            ) {
                if (item.until && !_.isEmpty(item.until)) {
                    const now = new Date().getTime();
                    const until = new Date(item.until).getTime();
                    return until > now;
                }

                console.log(`without until`, item.until);
                return !item.until;
            }

            return false;
        },

        submit() {
            this.error = false;
            this.form.server_id = this.product.server_id;
            this.form.group_id = this.product.group.id;

            axios
                .post(this.route('donate.buy'), this.form)
                .then(response => {
                    if (response.data.success !== true) {
                        this.error = true;
                        this.message = response.data.message;
                        this.form.recentlySuccessful = false;
                        return;
                    }

                    this.error = false;
                    this.$emit('update', response.data.message);
                    this.close();
                })
                .catch(error => {
                    this.error = true;
                    this.form.recentlySuccessful = false;
                    this.message =
                        'Ошибки при выполнении запроса. Попробуйте еще раз';
                });
        },
    },
};
</script>
