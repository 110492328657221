<template>
    <modal-component :show="show" @close="close">
        <template #title> Авторизация </template>
        <template #content>
            <form class="login-form" name="login-form" @submit.prevent="submit">
                <div class="input-group">
                    <jet-label for="login-name" value="Логин" />
                    <jet-input
                        id="login-name"
                        v-model="form.name"
                        autocomplete
                        class="input-login"
                        required
                        type="text" />
                </div>

                <div class="input-group">
                    <jet-label for="login-password" value="Пароль" />
                    <jet-input
                        id="login-password"
                        v-model="form.password"
                        autocomplete="current-password"
                        class="input-login"
                        required
                        type="password" />
                </div>

                <div class="login-forget__wrapper">
                    <button
                        class="login-forget"
                        type="button"
                        @click="openForgetPasswordModal()">
                        Забыли пароль?
                    </button>
                </div>

                <button
                    :class="{'button--process': form.processing}"
                    :disabled="form.processing"
                    class="button button--blue login-button"
                    type="submit">
                    Войти в аккаунт
                </button>

                <button
                    class="login-create"
                    type="button"
                    @click="openRegisterModal()">
                    Создать новый аккаунт
                </button>
            </form>
        </template>
        <template #close>
            <button class="modal__close" type="button" @click="close()">
                <close-modal-icon />
            </button>
        </template>
    </modal-component>
</template>

<script>
import ModalComponent from '@/Components/Reusable/ModalComponent.vue';

import JetButton from '@/Jetstream/Button.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import JetLabel from '@/Jetstream/Label.vue';
import CloseModalIcon from '@/Components/Reusable/CloseModalIcon.vue';

export default {
    name: 'LoginModalComponent',
    components: {
        ModalComponent,
        JetButton,
        JetInput,
        JetCheckbox,
        JetLabel,
        CloseModalIcon,
    },
    props: ['show'],
    emits: ['close', 'open-register-modal', 'open-forget-password-modal'],
    data() {
        return {
            form: this.$inertia.form({
                name: '',
                password: '',
                remember: true,
            }),
        };
    },
    methods: {
        close() {
            this.$emit('close');
        },
        openRegisterModal() {
            this.$emit('open-register-modal');
        },
        openForgetPasswordModal() {
            this.$emit('open-forget-password-modal');
        },
        submit() {
            this.form
                .transform(data => ({
                    ...data,
                    remember: this.form.remember ? 'on' : '',
                }))
                .post('/login', {
                    onBefore: () => (this.$page.props.errors = {}),
                    onFinish: () => this.form.reset('password'),
                });
        },
    },
};
</script>
