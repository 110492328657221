<template>
    <app-layout title="Бан-лист">
        <div class="container">
            <section class="bans section">
                <div class="section__container">
                    <h3 class="text-title text-dark text-center section__h3">
                        Бан-лист
                    </h3>
                    <alert-component
                        v-if="error"
                        :rounded="true"
                        type="error"
                        v-html="error"></alert-component>
                    <div v-else-if="$page.props.user && bans">
                        <alert-component
                            v-if="userBanned"
                            :rounded="true"
                            type="error">
                            Вы найдены в списке временно заблокированных.<br />
                            Соблюдайте
                            <Link :href="route('rules')">правила проекта</Link>
                            и никогда сюда не попадете.
                        </alert-component>
                        <alert-component v-else :rounded="true" type="success">
                            Вы не найдены в списке временно заблокированных.<br />
                            Соблюдайте
                            <Link :href="route('rules')">правила проекта</Link>
                            и никогда сюда не попадете.
                        </alert-component>
                    </div>
                    <alert-component
                        v-else-if="!bans"
                        :rounded="true"
                        type="info"
                        v-html="
                            'Для продолжения выберите сервер'
                        "></alert-component>

                    <form
                        class="bans__panel"
                        name="bans-form"
                        @submit.prevent="submit">
                        <div class="input-group">
                            <jet-label for="server" value="Выберите сервер:" />
                            <select
                                id="server"
                                v-model="form.server"
                                class="input input-server input-select"
                                required>
                                <option disabled selected="selected" value="">
                                    Выберите сервер
                                </option>
                                <option
                                    v-for="(server, index) in servers"
                                    :key="index"
                                    :value="server.slug"
                                    v-html="server.name"></option>
                            </select>
                        </div>

                        <div class="input-group search">
                            <jet-label
                                for="search"
                                value="Введите ник модератора/нарушителя или укажите причину:" />
                            <jet-input
                                id="search"
                                v-model="form.search"
                                class="input-search"
                                placeholder="Ник нарушителя, модератора или причина"
                                type="text" />
                        </div>
                    </form>

                    <div v-if="bans && bans.data">
                        <div class="table-responsive">
                            <table class="table table--bans table-hover">
                                <thead>
                                    <tr>
                                        <td>Нарушитель</td>
                                        <td>Покарал</td>
                                        <td>Нарушение</td>
                                        <td>Дата бана</td>
                                        <td>Разблокировка</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(user, index) in bans.data"
                                        v-if="manager === 'MaxBans'"
                                        :key="index">
                                        <td>{{ user.name }}</td>
                                        <td>{{ user.banner }}</td>
                                        <td class="reason">
                                            <tooltip-component
                                                :tooltip="user.reason">
                                                {{ user.reason }}
                                            </tooltip-component>
                                        </td>
                                        <td>{{ convertDate(user.time) }}</td>
                                        <td
                                            :class="{
                                                banned: user.expires === 0,
                                            }">
                                            {{
                                                user.expires === 0
                                                    ? 'Бессрочно'
                                                    : convertDate(user.expires)
                                            }}
                                        </td>
                                    </tr>
                                    <tr
                                        v-for="(user, index) in bans.data"
                                        v-else-if="manager === 'AdvancedBan'"
                                        :key="user.id">
                                        <td>{{ user.name }}</td>
                                        <td>
                                            {{
                                                user.operator === 'CONSOLE'
                                                    ? 'Console'
                                                    : user.operator
                                            }}
                                        </td>
                                        <td class="reason">
                                            <tooltip-component
                                                :tooltip="user.reason">
                                                {{ user.reason }}
                                            </tooltip-component>
                                        </td>
                                        <td>{{ convertDate(user.start) }}</td>
                                        <td
                                            :class="{
                                                banned: user.end === '-1',
                                            }">
                                            {{
                                                user.end === '-1'
                                                    ? 'Бессрочно'
                                                    : convertDate(user.end)
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <pagination-component :links="bans.links" />
                    </div>
                </div>
            </section>
        </div>
    </app-layout>
</template>

<script>
import AlertComponent from '@/Components/Reusable/AlertComponent.vue';
import PaginationComponent from '@/Components/PaginationComponent.vue';
import TooltipComponent from '@/Components/Reusable/TooltipComponent.vue';
import {Link} from '@inertiajs/vue3';
import AppLayout from '@/Layouts/AppLayout.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetLabel from '@/Jetstream/Label.vue';
import {router} from '@inertiajs/vue3';
import {stringify} from 'qs';
import NotifyComponent from '@/Components/Reusable/NotifyComponent.vue';
import debounce from 'lodash/debounce';
import moment from 'moment';

export default {
    components: {
        NotifyComponent,
        AppLayout,
        AlertComponent,
        PaginationComponent,
        TooltipComponent,
        Link,
        JetInput,
        JetLabel,
    },
    props: {
        servers: Array,
        error: String,
        initialServer: String,
        initialSearch: String,

        bans: Object,
        userBanned: Boolean,
        manager: String,
    },
    data() {
        return {
            form: this.$inertia.form({
                server: null,
                search: null,
            }),
            loaded: false,
        };
    },
    mounted() {
        this.form.server = this.initialServer;
        this.form.search = this.initialSearch;

        if (this.initialServer !== null) this.submit();
    },
    methods: {
        submit() {
            this.loaded = true;

            const query = stringify({
                search: this.form.search || undefined,
                server: this.form.server || undefined,
                page: this.form.search
                    ? undefined
                    : this.bans?.current_page || undefined,
            });

            router.visit(
                query
                    ? `${this.route('bans')}?${query}`
                    : `${this.route('bans')}`,
                {
                    preserveScroll: true,
                    preserveState: true,
                    only: ['bans', 'userBanned', 'manager'],
                },
            );
        },
        convertDate: date =>
            moment.unix(parseInt(date) / 1000).format('DD.MM.YYYY HH:mm'),
    },
    watch: {
        'form.server': debounce(function () {
            if (this.loaded) this.submit();
        }, 100),
        'form.search': debounce(function () {
            if (this.loaded) this.submit();
        }, 150),
    },
};
</script>
