<template>
    <Popper :content="tooltip" :hover="true" :placement="placement">
        <slot></slot>
    </Popper>
</template>

<script>
import Popper from 'vue3-popper';

export default {
    components: {
        Popper,
    },
    props: {
        tooltip: {
            default: '',
        },
        placement: {
            default: 'bottom-start',
        },
    },
};
</script>

<style lang="scss" scoped>
.inline-block {
    display: block;
}

:deep(div:first-child) {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 230px;
    white-space: nowrap;
}

:deep(.popper) {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    white-space: pre;
}
</style>
