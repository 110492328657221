<template>
    <footer v-if="footer" class="footer menu">
        <!--        <menu-component :footer="footer"></menu-component>-->

        <div class="menu__links">
            <Link class="link" :href="route('agreement')"
                >Пользовательское соглашение</Link
            >
            <Link class="link" :href="route('privacy')"
                >Политика конфиденциальности</Link
            >
            <Link class="link" :href="route('contacts')">Контакты</Link>
            <Link class="link" :href="route('rules')">Правила</Link>
        </div>

        <div class="menu__info">
            <a class="link" href="//shbov.ru" target="_blank"
                >Дизайн & Разработка: WebFocus with @shbov</a
            >
            <a
                class="link"
                target="_blank"
                href="https://www.minecraft.net/en-us"
                >Все права на игру принадлежат Mojang AB. Приобрести игру можно
                на официальном сайте</a
            >
        </div>
    </footer>
    <header v-else class="menu">
        <menu-component :footer="footer"></menu-component>
    </header>
</template>

<script>
import MenuComponent from './MenuComponent.vue';
import {Link} from '@inertiajs/vue3';

export default {
    name: 'HeaderComponent',
    components: {
        MenuComponent,
        Link,
    },
    props: {
        footer: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        const header = document.querySelector('header.menu');

        if (header) {
            let scrollPos = window.scrollY;
            const header_height = header.offsetHeight;

            const add_class_on_scroll = () => header.classList.add('scrolling');
            const remove_class_on_scroll = () =>
                header.classList.remove('scrolling');

            window.addEventListener('scroll', function () {
                scrollPos = window.scrollY;

                if (scrollPos >= header_height) {
                    add_class_on_scroll();
                } else {
                    remove_class_on_scroll();
                }
            });
        }
    },
};
</script>
