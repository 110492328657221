<template>
    <modal-component :show="show" @close="close">
        <template #title> Регистрация </template>

        <template #content>
            <form
                class="login-form"
                name="register-form"
                @submit.prevent="submit">
                <div class="input-group">
                    <jet-label for="name" value="Никнейм" />
                    <jet-input
                        id="name"
                        v-model="form.name"
                        :placeholder="randomName && `Например, ${randomName}`"
                        autocomplete
                        :class="{
                            err: !userNameCheck.success,
                            loading: userNameCheck.isLoading,
                            success: userNameCheck.success,
                        }"
                        class="input-login"
                        required
                        :disabled="userNameCheck.isLoading"
                        type="text" />
                    <span
                        class="input-desc"
                        :class="{
                            err: userNameCheck.data && !userNameCheck.success,
                            success:
                                userNameCheck.data && userNameCheck.success,
                        }"
                        v-html="
                            userNameCheck.data ??
                            'Должен состоять только из&nbsp;букв, цифр и нижнего подчеркивания'
                        " />
                </div>

                <div class="input-group">
                    <jet-label for="email" value="Почта" />
                    <jet-input
                        id="email"
                        v-model="form.email"
                        :class="{
                            err: !emailCheck.success,
                            loading: emailCheck.isLoading,
                            success: emailCheck.success,
                        }"
                        autocomplete
                        :disabled="emailCheck.isLoading"
                        class="input-login"
                        required
                        type="email" />
                    <span
                        class="input-desc"
                        :class="{
                            err: emailCheck.data && !emailCheck.success,
                            success: emailCheck.data && emailCheck.success,
                        }"
                        v-html="
                            emailCheck.data ?? 'Введите корректный email-адрес'
                        " />
                </div>

                <div class="input-group">
                    <jet-label for="password" value="Пароль" />
                    <jet-input
                        id="password"
                        v-model="form.password"
                        autocomplete="new-password"
                        class="input-login"
                        required
                        type="password" />
                </div>

                <div class="input-group">
                    <jet-label
                        for="password_confirmation"
                        value="Повторите пароль" />
                    <jet-input
                        id="password_confirmation"
                        v-model="form.password_confirmation"
                        autocomplete="new-password"
                        class="input-login"
                        required
                        type="password" />
                    <span
                        v-if="
                            passwordInitted &&
                            !arePasswordsCorrect(
                                form.password,
                                form.password_confirmation,
                            )
                        "
                        class="input-desc err">
                        Введенные пароли должны совпадать и&nbsp;содержать как
                        минимум 8&nbsp;символов
                    </span>
                </div>

                <div class="input-group">
                    <jet-label for="register-terms">
                        <div class="register-terms">
                            <div>
                                Продолжая регистрацию, вы&nbsp;подтверждаете,
                                что полностью согласны и&nbsp;ознакомлены с
                                <a :href="route('rules')" target="_blank"
                                    >правилами</a
                                >,
                                <a :href="route('privacy')" target="_blank"
                                    >политикой</a
                                >
                                и
                                <a :href="route('agreement')" target="_blank"
                                    >соглашением</a
                                >
                                на&nbsp;HardMine
                            </div>
                        </div>
                    </jet-label>
                </div>

                <button
                    :class="{
                        'button--process': isDisabled,
                    }"
                    :disabled="isDisabled"
                    class="button button--blue login-button"
                    type="submit">
                    Завершить регистрацию
                </button>
            </form>
        </template>

        <template #close>
            <button class="modal__close" type="button" @click="close()">
                <close-modal-icon />
            </button>
        </template>
    </modal-component>
</template>

<script>
import ModalComponent from '@/Components/Reusable/ModalComponent.vue';

import JetButton from '@/Jetstream/Button.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import JetLabel from '@/Jetstream/Label.vue';

import {Link} from '@inertiajs/vue3';
import CloseModalIcon from '@/Components/Reusable/CloseModalIcon.vue';
import debounce from 'lodash/debounce';
import axios from 'axios';

export default {
    name: 'RegisterModalComponent',
    components: {
        ModalComponent,
        JetButton,
        JetInput,
        JetCheckbox,
        JetLabel,
        CloseModalIcon,
        Link,
    },

    props: ['show'],
    emits: ['close'],
    data() {
        return {
            randomName: '',
            formDisabled: true,
            passwordInitted: false,

            userNameCheck: {
                isLoading: false,
                success: false,
                data: null,
            },

            emailCheck: {
                isLoading: false,
                success: false,
                data: null,
            },

            form: this.$inertia.form({
                name: '',
                email: '',
                password: '',
                password_confirmation: '',
                terms: true,
            }),
        };
    },

    computed: {
        isDisabled() {
            return (
                this.formDisabled ||
                !this.form.terms ||
                !this.userNameCheck.success ||
                !this.emailCheck.success
            );
        },
    },

    watch: {
        'form.name': function (...args) {
            this.debouncedLogin(...args);
        },

        'form.email': function (...args) {
            this.debouncedEmail(...args);
        },

        'form.password': function (val) {
            this.passwordInitted = true;
            this.formDisabled = !this.arePasswordsCorrect(
                val,
                this.form.password_confirmation,
            );
        },

        'form.password_confirmation': function (val) {
            this.passwordInitted = true;
            this.formDisabled = !this.arePasswordsCorrect(
                val,
                this.form.password,
            );
        },
    },

    created() {
        this.debouncedLogin = debounce(login => {
            this.userNameCheck.isLoading = true;
            axios
                .get('/api/user/check', {
                    params: {
                        name: login,
                    },
                })
                .then(({data}) => {
                    this.userNameCheck.success = data.success;
                    this.userNameCheck.data = data.data.name[0] ?? null;

                    this.userNameCheck.isLoading = false;
                })
                .catch(() => {
                    this.userNameCheck.success = false;
                    this.userNameCheck.data = null;
                    this.userNameCheck.isLoading = false;
                });
        }, 500);

        this.debouncedEmail = debounce(email => {
            this.emailCheck.isLoading = true;
            axios
                .get('/api/user/emailCheck', {
                    params: {
                        email: email,
                    },
                })
                .then(({data}) => {
                    this.emailCheck.success = data.success;
                    this.emailCheck.data = data.data.email[0] ?? null;

                    this.emailCheck.isLoading = false;
                })
                .catch(() => {
                    this.emailCheck.success = false;
                    this.emailCheck.data = null;
                    this.emailCheck.isLoading = false;
                });
        }, 500);
    },

    methods: {
        arePasswordsCorrect(password1, password2) {
            if (password1?.trim().length < 8 || password2?.trim().length < 8) {
                return false;
            }

            return password1 === password2;
        },

        close() {
            this.$emit('close');
        },

        submit() {
            this.form.post(this.route('register'), {
                onSuccess: () => {
                    ym(61902063, 'reachGoal', 'register_success');
                },
                onBefore: () => {
                    this.$page.props.errors = {};
                },
                onFinish: () => {
                    this.form.reset('password', 'password_confirmation');
                },
            });
        },
    },
};
</script>
