<script setup>
import AppLayout from '../Layouts/AppLayout.vue';
import LauncherModalComponent from '../Components/Modals/LauncherModalComponent.vue';
import {onBeforeMount, ref} from 'vue';
import LauncherInfo from '../Components/LauncherInfo.vue';

const title = 'Скачать лаунчер';
const showLauncherModal = ref(false);

onBeforeMount(() => {
    showLauncherModal.value = true;
});
</script>

<template>
    <app-layout :title="title">
        <div class="container">
            <section class="top section">
                <div class="section__container">
                    <h3
                        class="text-title text-dark section__h3"
                        v-text="title" />
                    <launcher-info />
                    <launcher-modal-component
                        :show="showLauncherModal"
                        @close="showLauncherModal = false" />
                </div>
            </section>
        </div>
    </app-layout>
</template>
