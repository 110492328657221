<template>
    <app-layout title="Донат">
        <div class="container">
            <section class="donate">
                <Select @server-changed="serverChanged" />
                <notify-component v-if="error" :message="error" type="error" />

                <div
                    v-else-if="
                        server && server.groups && server.groups.length > 0
                    "
                    class="donate__wrapper">
                    <div class="donate__groups">
                        <div
                            v-for="group in server.groups"
                            :key="group.id"
                            class="donate__groups--item">
                            <img
                                :alt="group.name"
                                :src="
                                    '/build/images/group--' +
                                    group.pex +
                                    '.webp'
                                " />
                            <p v-html="group.short_description" />
                            <hr />
                            <h5>от {{ findMin(group.types) }} ₽</h5>
                            <a
                                class="button button--blue"
                                href="#"
                                :class="{'button--disabled': !$page.props.user}"
                                @click.prevent="
                                    $page.props.user && buy(server.id, group)
                                ">
                                посмотреть
                            </a>
                        </div>
                    </div>
                </div>

                <div
                    v-if="
                        !error &&
                        server &&
                        server.groups &&
                        server.groups.length > 0
                    ">
                    <component
                        :is="server.slug + 'DonateTable'"
                        :kits="server.kits"
                        :server="server.name" />
                </div>
            </section>
        </div>

        <notify-component v-if="success" :message="message" type="success" />
        <donate-modal-component
            v-if="showDonateModal"
            :product="active"
            :server="server"
            :show="showDonateModal"
            @close="showDonateModal = false"
            @update="update" />
    </app-layout>
</template>

<script>
import {Head} from '@inertiajs/vue3';
import AppLayout from '@/Layouts/AppLayout.vue';
import TooltipComponent from '@/Components/Reusable/TooltipComponent.vue';
import Select from './Select.vue';
import debounce from 'lodash/debounce';
import AlertComponent from '@/Components/Reusable/AlertComponent.vue';
import NotifyComponent from '@/Components/Reusable/NotifyComponent.vue';
import {router} from '@inertiajs/vue3';
import DonateModalComponent from '../../Components/Modals/DonateModalComponent.vue';
import zombieDonateTable from '../Servers/Tables/zombieDonateTable.vue';
import vanillaDonateTable from '../Servers/Tables/vanillaDonateTable.vue';
import hitechDonateTable from '../Servers/Tables/hitechDonateTable.vue';
import dtmDonateTable from '../Servers/Tables/dtmDonateTable.vue';

export default {
    name: 'Donate',
    components: {
        DonateModalComponent,
        Select,
        Head,
        AppLayout,
        TooltipComponent,
        AlertComponent,
        NotifyComponent,

        zombieDonateTable,
        vanillaDonateTable,
        hitechDonateTable,
        dtmDonateTable,
    },

    props: {
        error: String,
        initialServer: String,
        servers: Array,
    },

    data() {
        return {
            server: null,
            slug: null,
            active: null,
            success: null,
            message: null,
            form: {
                server_id: 1,
                group_id: 1,
            },
            showDonateModal: false,
        };
    },

    methods: {
        serverChanged(slug) {
            this.slug = slug;
        },

        buy(server_id, group) {
            this.active = {
                server_id: server_id,
                group: group,
            };

            this.showDonateModal = true;
        },

        update(message) {
            this.success = true;
            this.message = message;

            router.reload({});
        },

        findMin(data) {
            let arr = JSON.parse(data);

            return arr.reduce((prev, curr) => {
                if (!curr.until && curr.newPrice) {
                    if (prev === null) {
                        return Math.min(curr.price, curr.newPrice);
                    }

                    return Math.min(prev, curr.price, curr.newPrice);
                }

                if (
                    curr.until &&
                    curr.newPrice &&
                    new Date(curr.until).getTime() > new Date().getTime()
                ) {
                    if (prev === null) {
                        return Math.min(curr.price, curr.newPrice);
                    }

                    return Math.min(prev, curr.price, curr.newPrice);
                }

                if (prev === null) {
                    return curr.price;
                }

                return Math.min(prev, curr.price);
            }, null);
        },
    },

    watch: {
        slug: debounce(function () {
            this.server = this.servers.filter(
                item => item.slug === this.slug,
            )[0];
        }, 0),

        error: debounce(function () {
            if (this.error !== null)
                setTimeout(function () {
                    router.visit(this.route('donate'));
                }, 3000);
        }, 0),
    },
};
</script>
