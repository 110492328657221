<template>
    <notify-component
        v-if="message"
        :message="message"
        type="error"></notify-component>
</template>

<script>
import NotifyComponent from '@/Components/Reusable/NotifyComponent.vue';

export default {
    components: {NotifyComponent},
    props: ['message'],
};
</script>
