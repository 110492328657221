<template>
    <div class="input-wrapper">
        <input
            :id="props.id"
            ref="input"
            :value="modelValue"
            :placeholder="props.placeholder"
            class="input"
            :class="props.class"
            :autocomplete="props.autocomplete"
            :type="props.type"
            :disabled="props.disabled"
            :required="props.required"
            @input="$emit('update:modelValue', $event.target.value)" />

        <button
            v-if="type === 'password'"
            class="input-showpassword"
            type="button"
            @click.prevent="togglePassword">
            <img
                v-if="!state.showPassword"
                :src="`/build/svg/hide-password.svg`"
                :alt="'Показать пароль'" />
            <img
                v-else
                :src="`/build/svg/show-password.svg`"
                :alt="'Скрыть пароль'" />
        </button>
    </div>
</template>

<script setup>
import {reactive, ref, watch} from 'vue';

const props = defineProps([
    'modelValue',
    'type',
    'id',
    'autocomplete',
    'placeholder',
    'class',
    'required',
    'disabled',
]);

defineEmits(['update:modelValue']);
const input = ref(null);

const state = reactive({
    showPassword: false,
});

const togglePassword = () => {
    state.showPassword = !state.showPassword;
};

watch(state, newState => {
    const type = newState.showPassword ? 'text' : 'password';
    input.value?.setAttribute('type', type);
});
</script>
