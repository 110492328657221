<template>
    <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.22419 4.81099C6.03559 4.62883 5.78299 4.52803 5.52079 4.53031C5.2586 4.53259 5.00778 4.63776 4.82238 4.82317C4.63697 5.00858 4.5318 5.25939 4.52952 5.52158C4.52724 5.78378 4.62804 6.03638 4.81019 6.22499L10.5852 12L4.80919 17.775C4.71368 17.8672 4.6375 17.9776 4.58509 18.0996C4.53268 18.2216 4.5051 18.3528 4.50394 18.4856C4.50279 18.6184 4.52809 18.75 4.57837 18.8729C4.62865 18.9958 4.70291 19.1075 4.7968 19.2014C4.89069 19.2953 5.00234 19.3695 5.12524 19.4198C5.24814 19.4701 5.37982 19.4954 5.5126 19.4942C5.64537 19.4931 5.77659 19.4655 5.8986 19.4131C6.0206 19.3607 6.13095 19.2845 6.22319 19.189L11.9992 13.414L17.7742 19.189C17.9628 19.3711 18.2154 19.4719 18.4776 19.4697C18.7398 19.4674 18.9906 19.3622 19.176 19.1768C19.3614 18.9914 19.4666 18.7406 19.4689 18.4784C19.4711 18.2162 19.3704 17.9636 19.1882 17.775L13.4132 12L19.1882 6.22499C19.3704 6.03638 19.4711 5.78378 19.4689 5.52158C19.4666 5.25939 19.3614 5.00858 19.176 4.82317C18.9906 4.63776 18.7398 4.53259 18.4776 4.53031C18.2154 4.52803 17.9628 4.62883 17.7742 4.81099L11.9992 10.586L6.22419 4.80999V4.81099Z"
            fill="#2C3147" />
    </svg>
</template>

<script>
export default {
    name: 'CloseModalIcon',
};
</script>

<style scoped></style>
