<template>
    <Popper
        :content="tooltip"
        :hover="true"
        :placement="placement"
        :class="{clickable: clickable}">
        <slot></slot>
    </Popper>
</template>

<script>
import Popper from 'vue3-popper';

export default {
    components: {
        Popper,
    },
    props: {
        tooltip: {
            default: '',
        },
        clickable: {
            default: '',
        },
        placement: {
            default: 'bottom-start',
        },
    },
};
</script>

<style lang="scss" scoped>
.inline-block {
    display: block;
}

:deep(.popper) {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    white-space: pre;
}

.clickable {
    cursor: pointer;
}
</style>
