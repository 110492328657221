<template>
    <div class="table-responsive">
        <table class="table table--market table-hover">
            <thead>
                <tr>
                    <td class="image"></td>
                    <td>Предмет</td>
                    <td v-if="showSeller">Продавец</td>
                    <td>Кол-во</td>
                    <td v-if="showPrice">Цена за 1 шт.</td>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(product, index) in items.data"
                    :key="index"
                    :class="{buy: $page.props.user}"
                    @click.prevent="
                        $page.props.user && $emit('buy-item', product)
                    ">
                    <td class="image">
                        <tooltip-component :tooltip="'ID: ' + product.item_id">
                            <img
                                :src="
                                    '/uploads/minecraft/' +
                                    product.meta?.texture
                                "
                                alt="" />
                        </tooltip-component>
                    </td>
                    <td>
                        <tooltip-component :tooltip="getDesc(product)">
                            {{ product.item_title ?? product.meta?.locale }}
                        </tooltip-component>
                    </td>
                    <td v-if="showSeller" v-html="product.seller"></td>
                    <td v-html="product.item_count + ' шт.'"></td>
                    <td
                        v-if="showPrice"
                        v-html="product.per_item_price + '/шт.'"></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import TooltipComponent from '@/Components/Reusable/TooltipComponent.vue';

export default {
    name: 'MarketTable',
    components: {TooltipComponent},
    props: {
        items: {},
        showSeller: {},
        showPrice: {},
    },
    emits: ['buy-item'],
    methods: {
        getDesc(product) {
            if (product.item_desc && JSON.parse(product.item_desc).length > 0)
                return JSON.parse(product.item_desc).join(`\r\n`);
        },
    },
};
</script>
