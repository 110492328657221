<template>
    <div>
        <Head :title="title" />

        <promo-block-component></promo-block-component>
        <monitoring-component
            :servers="$page.props.servers"
            :single="true"
            color="dark"></monitoring-component>
        <features-component></features-component>

        <section class="third">
            <social-component></social-component>
            <header-component footer></header-component>
        </section>
    </div>
</template>

<script>
import {Head} from '@inertiajs/vue3';
import MonitoringComponent from '@/Components/Home/MonitoringComponent.vue';
import SocialComponent from '@/Components/SocialComponent.vue';
import HeaderComponent from '@/Components/HeaderComponent.vue';
import FeaturesComponent from '@/Components/Promo/FeaturesComponent.vue';
import PromoBlockComponent from '@/Components/Promo/PromoBlockComponent.vue';

export default {
    components: {
        PromoBlockComponent,
        FeaturesComponent,
        Head,
        MonitoringComponent,
        SocialComponent,
        HeaderComponent,
    },
    props: {
        title: String,
    },
    created() {
        document.querySelector('body').classList.remove('main');
        document.querySelector('body').classList.add('promo');
    },
};
</script>
