<template>
    <app-layout title="Рейтинг голосующих игроков">
        <div class="container">
            <section class="top section">
                <div class="section__container top__container">
                    <div v-if="months" class="market__tabs">
                        <div
                            v-for="item in months"
                            :key="`${item.month}-${item.year}`"
                            :data-date="`${item.month}-${item.year}`"
                            :data-current="`${currentMonth}-${currentYear}`"
                            :class="{
                                active:
                                    String(item.month) ===
                                        String(currentMonth) &&
                                    String(item.year) === String(currentYear),
                            }"
                            class="market__tabs--item">
                            <Link
                                :href="
                                    route('vote.details', {
                                        month: item.month,
                                        year: item.year,
                                    })
                                "
                                preserve-scroll
                                v-text="item.name" />
                        </div>
                    </div>

                    <h3 class="text-title text-dark section__h3">
                        Рейтинг голосующих игроков
                    </h3>
                    <alert-component :rounded="true" type="success">
                        Каждый месяц среди всех тех, кто голосует за нас на
                        мониторингах, мы разыгрываем <b>3 привилегии</b> и еще
                        <b>5 различных призов</b>, получить которые можно на
                        любом сервере!<br />
                        Чтобы стать участником розыгрыша &mdash; стремись занять
                        одну из первых восьми позиций в этом рейтинге.
                    </alert-component>

                    <div class="table-responsive">
                        <table class="table table--votes table-colored">
                            <thead>
                                <tr>
                                    <td class="votes">#</td>
                                    <td>Игрок</td>
                                    <td>Количество голосов</td>
                                    <td>Приз</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(user, index) in votes.data"
                                    :key="index">
                                    <td
                                        class="votes"
                                        v-html="getPlace(user, votes)" />
                                    <td v-html="user?.name" />
                                    <td>
                                        Проголосовал(-а) {{ user.votes }}
                                        {{
                                            declOfNum(user.votes, [
                                                'раз',
                                                'раза',
                                                'раз',
                                            ])
                                        }}
                                    </td>
                                    <td
                                        :class="
                                            getReward(
                                                user,
                                                getPlace(user, votes),
                                            )[1]
                                        "
                                        v-html="
                                            getReward(
                                                user,
                                                getPlace(user, votes),
                                            )[0]
                                        " />
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <pagination-component :links="votes.links" />
                </div>
            </section>
        </div>

        <go-vote-component />
    </app-layout>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import AlertComponent from '@/Components/Reusable/AlertComponent.vue';
import PaginationComponent from '@/Components/PaginationComponent.vue';
import GoVoteComponent from '@/Components/GoVoteComponent.vue';
import {declOfNum} from '@/Methods/declOfNum.js';
import {Link} from '@inertiajs/vue3';

export default {
    components: {
        GoVoteComponent,
        AppLayout,
        AlertComponent,
        PaginationComponent,
        Link,
    },
    props: ['votes', 'currentPlace', 'currentMonth', 'months', 'currentYear'],
    methods: {
        declOfNum: declOfNum,
        getPlace: (user, votes) =>
            user.place + (votes.current_page - 1) * votes.per_page + 1,
        getReward: (user, place) => {
            switch (place) {
                case 1:
                    return ['Получит PLT на 30 дней', 'green'];
                case 2:
                    return ['Получит PRM на 30 дней', 'green'];
                case 3:
                    return ['Получит VIP на 30 дней', 'green'];
                case 4:
                case 5:
                    return ['Получит 1 Top кейс', 'yellow'];
                case 6:
                case 7:
                case 8:
                    return ['Получит 3 Bonus кейса', 'red'];
                default:
                    return ['', ''];
            }
        },
    },
};
</script>
