<template>
    <section class="go-vote">
        <div class="container">
            <div class="go-vote__title">
                <h3 class="text-title text-shop">Голосование</h3>
                <h4 class="text-subtitle text-shop">
                    Голосуй за наш проект на мониторингах. Получай каждый день баланс лк и бонусные кейсы.
                    Топ игроков по голосам за месяц гарантировано получают привилегии и другие ценные призы.
                </h4>
            </div>
            <div class="go-vote__wrapper">
                <div class="go-vote__cards">
                    <a
                        class="go-vote__cards--item"
                        href="https://topcraft.club/servers/8222/"
                        target="_blank">
                        <h5>TopCraft.club</h5>
                        <img alt="" src="/build/svg/goVote--1.svg" />
                        <button class="button button--blue">Голосовать</button>
                    </a>

                    <a
                        class="go-vote__cards--item"
                        href="https://mctop.su/servers/4984/"
                        target="_blank">
                        <h5>McTop.su</h5>
                        <img alt="" src="/build/svg/goVote--2.svg" />
                        <button class="button button--blue">Голосовать</button>
                    </a>

                    <a
                        class="go-vote__cards--item"
                        href="http://mcrate.su/rate/7831"
                        target="_blank">
                        <h5>McRate.su</h5>
                        <img alt="" src="/build/svg/goVote--3.svg" />
                        <button class="button button--blue">Голосовать</button>
                    </a>

                    <div
                        class="go-vote__cards--item last"
                        @click="showBonusModal = true">
                        <h5>БОНУСЫ!</h5>
                        <img alt="" src="/build/svg/goVote--4.svg" />
                        <button
                            class="button button--pink"
                            @click="showBonusModal = true">
                            Подробнее
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <bonus-modal-component
        :show="showBonusModal"
        @close="showBonusModal = false"></bonus-modal-component>
</template>

<script>
import {Link} from '@inertiajs/vue3';
import BonusModalComponent from './Modals/BonusModalComponent.vue';

export default {
    name: 'GoVoteComponent',
    components: {
        Link,
        BonusModalComponent,
    },
    data() {
        return {
            showBonusModal: false,
        };
    },
};
</script>
