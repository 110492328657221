<template>
    <div class="table-wrapper">
        <div class="table-responsive">
            <table class="table table--donate table-hover">
                <thead>
                    <tr>
                        <td>Команды и возможности</td>
                        <td class="player">Игрок</td>
                        <td class="vip">VIP</td>
                        <td class="premium">Premium</td>
                        <td class="platinum">Platinum</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <tooltip-component>
                                Игнор игрока <b>/ignore</b>
                            </tooltip-component>
                        </td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Самоуничтожение <b>/suicide</b></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Магазин блоков <b>/shop</b></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Рынок <b>/market</b></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Покупка приваток <b>/house</b></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td><b>HUD</b> прочности брони</td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Отключение кика за <b>AFK</b></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Вход на заполненный сервер</td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Слоты в сундуке Эндера</td>
                        <td>0</td>
                        <td>9</td>
                        <td>18</td>
                        <td>27</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="table-wrapper">
        <div class="table-responsive">
            <table class="table table--donate table-hover">
                <thead>
                    <tr>
                        <td>Рынок</td>
                        <td class="player">Игрок</td>
                        <td class="vip">VIP</td>
                        <td class="premium">Premium</td>
                        <td class="platinum">Platinum</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Доступ к продаже на <b>/market</b></td>
                        <td><b>24</b> часа игры</td>
                        <td><b>20</b> часов игры</td>
                        <td><b>10</b> часов игры</td>
                        <td>Сразу</td>
                    </tr>
                    <tr>
                        <td>Кол-во слотов для продажи</td>
                        <td>18</td>
                        <td>27</td>
                        <td>36</td>
                        <td>54</td>
                    </tr>
                    <tr>
                        <td>Условия продажи</td>
                        <td>1 lvl игрока + 200$</td>
                        <td>1 lvl игрока + 100$</td>
                        <td>1 lvl игрока + 50$</td>
                        <td>1 lvl игрока</td>
                    </tr>
                    <tr>
                        <td>Налог с продажи предмета, %</td>
                        <td>16</td>
                        <td>14</td>
                        <td>8</td>
                        <td>0</td>
                    </tr>
                    <tr>
                        <td>Налог на снятие слота, %</td>
                        <td>10</td>
                        <td>8</td>
                        <td>4</td>
                        <td>2</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="table-wrapper">
        <div class="table-responsive">
            <table class="table table--donate table-hover">
                <thead>
                    <tr>
                        <td>Наборы</td>
                        <td class="player">Игрок</td>
                        <td class="vip">VIP</td>
                        <td class="premium">Premium</td>
                        <td class="platinum">Platinum</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <large-tooltip-component
                                :clickable="true"
                                content="Нажмите, чтобы узнать подробнее"
                                @click.prevent="showKits = true"
                                >Kit <b>Start</b>
                                <i class="icon icon-question ml-3"></i
                            ></large-tooltip-component>
                            <img
                                v-if="isKitsAvailable && parsedKits.length >= 1"
                                :alt="parsedKits[0].text"
                                :src="parsedKits[0].url"
                                class="server-image mt-8" />
                        </td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component
                                :clickable="true"
                                content="Нажмите, чтобы узнать подробнее"
                                @click.prevent="showKits = true"
                                >Kit <b>Vip1</b>
                                <i class="icon icon-question ml-3"></i
                            ></large-tooltip-component>
                            <img
                                v-if="isKitsAvailable && parsedKits.length >= 1"
                                :alt="parsedKits[1].text"
                                :src="parsedKits[1].url"
                                class="server-image mt-8" />
                        </td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component
                                :clickable="true"
                                content="Нажмите, чтобы узнать подробнее"
                                @click.prevent="showKits = true"
                                >Kit <b>Vip2</b>
                                <i class="icon icon-question ml-3"></i
                            ></large-tooltip-component>
                            <img
                                v-if="isKitsAvailable && parsedKits.length >= 1"
                                :alt="parsedKits[2].text"
                                :src="parsedKits[2].url"
                                class="server-image mt-8" />
                        </td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component
                                :clickable="true"
                                content="Нажмите, чтобы узнать подробнее"
                                @click.prevent="showKits = true"
                                >Kit <b>Prm1</b>
                                <i class="icon icon-question ml-3"></i
                            ></large-tooltip-component>
                            <img
                                v-if="isKitsAvailable && parsedKits.length >= 1"
                                :alt="parsedKits[3].text"
                                :src="parsedKits[3].url"
                                class="server-image mt-8" />
                        </td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-ban"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component
                                :clickable="true"
                                content="Нажмите, чтобы узнать подробнее"
                                @click.prevent="showKits = true"
                                >Kit <b>Prm2</b>
                                <i class="icon icon-question ml-3"></i
                            ></large-tooltip-component>
                            <img
                                v-if="isKitsAvailable && parsedKits.length >= 1"
                                :alt="parsedKits[4].text"
                                :src="parsedKits[4].url"
                                class="server-image mt-8" />
                        </td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-ban"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component
                                :clickable="true"
                                content="Нажмите, чтобы узнать подробнее"
                                @click.prevent="showKits = true"
                                >Kit <b>Prm3</b>
                                <i class="icon icon-question ml-3"></i
                            ></large-tooltip-component>
                            <img
                                v-if="isKitsAvailable && parsedKits.length >= 1"
                                :alt="parsedKits[5].text"
                                :src="parsedKits[5].url"
                                class="server-image mt-8" />
                        </td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-ban"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component
                                :clickable="true"
                                content="Нажмите, чтобы узнать подробнее"
                                @click.prevent="showKits = true"
                                >Kit <b>Plt1</b>
                                <i class="icon icon-question ml-3"></i
                            ></large-tooltip-component>
                            <img
                                v-if="isKitsAvailable && parsedKits.length >= 1"
                                :alt="parsedKits[6].text"
                                :src="parsedKits[6].url"
                                class="server-image mt-8" />
                        </td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component
                                :clickable="true"
                                content="Нажмите, чтобы узнать подробнее"
                                @click.prevent="showKits = true"
                                >Kit <b>Plt2</b>
                                <i class="icon icon-question ml-3"></i
                            ></large-tooltip-component>
                            <img
                                v-if="isKitsAvailable && parsedKits.length >= 1"
                                :alt="parsedKits[7].text"
                                :src="parsedKits[7].url"
                                class="server-image mt-8" />
                        </td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component
                                :clickable="true"
                                content="Нажмите, чтобы узнать подробнее"
                                @click.prevent="showKits = true"
                                >Kit <b>Plt3</b>
                                <i class="icon icon-question ml-3"></i
                            ></large-tooltip-component>
                            <img
                                v-if="isKitsAvailable && parsedKits.length >= 1"
                                :alt="parsedKits[8].text"
                                :src="parsedKits[8].url"
                                class="server-image mt-8" />
                        </td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component
                                :clickable="true"
                                content="Нажмите, чтобы узнать подробнее"
                                @click.prevent="showKits = true"
                                >Kit <b>Plt4</b>
                                <i class="icon icon-question ml-3"></i
                            ></large-tooltip-component>
                            <img
                                v-if="isKitsAvailable && parsedKits.length >= 1"
                                :alt="parsedKits[9].text"
                                :src="parsedKits[9].url"
                                class="server-image mt-8" />
                        </td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <kits-modal-component
        :show="showKits"
        :kits="kits"
        :server="server"
        @close="showKits = false"></kits-modal-component>
</template>

<script>
import LargeTooltipComponent from '@/Components/Reusable/LargeTooltipComponent.vue';
import KitsModalComponent from '@/Components/Modals/KitsModalComponent.vue';

export default {
    components: {KitsModalComponent, LargeTooltipComponent},
    props: ['kits', 'server'],
    data() {
        return {
            showKits: false,
        };
    },
    computed: {
        parsedKits() {
            return JSON.parse(this.kits);
        },
    },
    methods: {
        isKitsAvailable() {
            return this.kits && JSON.parse(this.kits).length > 0;
        },
    },
};
</script>
