<template>
    <Index current="index">
        <template #alert>
            <alert-component :rounded="true" type="info">
                На рынке Вы сможете создать свой персональный магазин, в котором
                игроки смогут их у Вас купить по цене, которую Вы лично
                устанавливаете. Так же для Вас доступны магазины других игроков.
            </alert-component>
        </template>

        <template #main>
            <h3 class="text-h2 text-dark">Фильтрация</h3>

            <form
                class="market__panel"
                name="market-form"
                @submit.prevent="submit">
                <div class="input-group search">
                    <jet-label for="id" value="Поиск по ID:" />
                    <jet-input
                        id="id"
                        v-model="form.id"
                        class="input-search"
                        placeholder="Введите ID товара"
                        type="text"
                        @keypress="isNumber($event)" />
                </div>

                <div class="input-group search">
                    <jet-label for="seller" value="Поиск по продавцу:" />
                    <jet-input
                        id="seller"
                        v-model="form.seller"
                        class="input-search"
                        placeholder="Введите ник продавца"
                        type="text" />
                </div>
            </form>

            <market-table
                :items="items"
                :show-price="true"
                :show-seller="true"
                @buy-item="buyItem"></market-table>
            <notify-component
                v-if="success"
                :message="message"
                type="success" />
            <market-modal-component
                v-if="showMarketModal"
                :product="activeProduct"
                :show="showMarketModal"
                @close="showMarketModal = false"
                @update="update"></market-modal-component>
            <pagination-component
                :count="items.total"
                :links="items.links"
                :market="true" />
        </template>
    </Index>
</template>

<script>
import Index from '@/Pages/Market/Index.vue';
import AlertComponent from '@/Components/Reusable/AlertComponent.vue';
import PaginationComponent from '@/Components/PaginationComponent.vue';
import TooltipComponent from '@/Components/Reusable/TooltipComponent.vue';
import {Link} from '@inertiajs/vue3';
import AppLayout from '@/Layouts/AppLayout.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetLabel from '@/Jetstream/Label.vue';
import NotifyComponent from '@/Components/Reusable/NotifyComponent.vue';
import {stringify} from 'qs';
import {router} from '@inertiajs/vue3';
import debounce from 'lodash/debounce';
import MarketModalComponent from '@/Components/Modals/MarketModalComponent.vue';
import {parseDate} from '@/Methods/parseDate.js';
import MarketTable from './MarketTable.vue';

export default {
    name: 'Items',
    components: {
        MarketTable,
        MarketModalComponent,
        Index,
        AppLayout,
        JetInput,
        JetLabel,
        NotifyComponent,
        AlertComponent,
        PaginationComponent,
        TooltipComponent,
        Link,
    },
    props: {
        items: Object,
        error: String,
        initialSeller: String,
        initialId: String,
    },
    data() {
        return {
            form: this.$inertia.form({
                seller: null,
                id: null,
            }),
            showMarketModal: false,
            activeProduct: null,
            success: false,
            message: '',
        };
    },
    mounted() {
        this.form.id = this.initialId;
        this.form.seller = this.initialSeller;
    },
    methods: {
        submit() {
            const query = stringify({
                id: this.form.id || undefined,
                seller: this.form.seller || undefined,
            });

            router.visit(
                query
                    ? `${this.route('market.index')}?${query}`
                    : `${this.route('market.index')}`,
                {
                    preserveScroll: true,
                    preserveState: true,
                },
            );
        },
        isNumber(evt) {
            evt = evt ? evt : window.event;
            let charCode = evt.which ? evt.which : evt.keyCode;

            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        parseDate: parseDate,
        buyItem(product) {
            this.activeProduct = product;
            this.showMarketModal = true;
            this.success = false;
        },
        update(message) {
            this.success = true;
            this.message = message;
            router.reload({
                // only: ['items']
            });
        },
    },
    watch: {
        'form.id': debounce(function () {
            this.submit();
        }, 0),
        'form.seller': debounce(function () {
            this.submit();
        }, 150),
    },
};
</script>
