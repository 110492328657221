<template>
    <teleport to="body">
        <div :data-open="show" class="modal">
            <div class="modal-wrapper">
                <div class="modal__content">
                    <h6 class="modal__content--title">
                        <slot name="title"></slot>
                    </h6>

                    <slot name="close"></slot>

                    <slot name="content"></slot>

                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script>
import {onMounted, onUnmounted} from 'vue';

export default {
    props: {
        show: {
            default: false,
        },
        closeable: {
            default: true,
        },
    },
    emits: ['close'],

    setup(props, {emit}) {
        const close = () => {
            if (props.closeable) {
                emit('close');
            }
        };

        const closeOnEscape = e => {
            if (e.key === 'Escape' && props.show) {
                close();
            }
        };

        onMounted(() => document.addEventListener('keydown', closeOnEscape));
        onUnmounted(() => {
            document.removeEventListener('keydown', closeOnEscape);
            document.body.classList.remove('overflow-hidden');
        });

        return {
            close,
        };
    },

    watch: {
        show: {
            immediate: true,
            handler: show => {
                if (show) {
                    document.body.classList.add('overflow-hidden');
                } else {
                    document.body.classList.remove('overflow-hidden');
                }
            },
        },
    },
};
</script>
