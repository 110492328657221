<template>
    <section
        id="servers"
        :class="{'monitoring-single': single}"
        class="monitoring">
        <div class="container">
            <h3
                :class="'text-' + color"
                class="text-title"
                v-text="single ? 'Наши сервера' : 'Мониторинг серверов'"></h3>
            <h4 :class="'text-' + color" class="text-subtitle">
                Прямо сейчас
                {{ declOfNum(totalOnline, ['играет', 'играют', 'играют']) }}:
                {{ totalOnline }}
                {{ declOfNum(totalOnline, ['человек', 'человека', 'человек']) }}
            </h4>

            <div class="monitoring__wrapper">
                <div class="monitoring__container">
                    <Link
                        v-for="server in servers"
                        :key="server.slug"
                        :href="route('servers.show', server.slug)"
                        class="monitoring__item image">
                        <div class="monitoring__item--image">
                            <img
                                :alt="server.name"
                                v-bind:style="{filter: server.onWipe&&'grayscale(1)'}"
                                :src="
                                    '/uploads/servers/' + server.slug + '.png'
                                " />
                            <span>Подробнее...</span>
                        </div>

                        <div class="monitoring__item--content">
                            <span
                                v-if="server.onWipe"
                                class="tag tag--status"
                                v-html="'На вайпе'" />
                            <span
                                v-else-if="server.max_online === 0"
                                class="tag tag--status">
                                Тех. работы
                            </span>
                            <!--<span
                                v-else
                                class="tag tag--version"
                                v-html="server.version"></span>-->

                            <div class="inline">
                                <h5
                                    class="monitoring__item--title"
                                    v-html="server.name"></h5>
                                <div
                                    v-if="server.max_online === 0"
                                    class="monitoring__item--players">
                                    <span class="off">OFF</span>
                                </div>
                                <div v-else class="monitoring__item--players">
                                    <span
                                        class="from"
                                        v-html="server.online"></span
                                    >/<span
                                        class="to"
                                        v-html="server.max_online"></span>
                                </div>
                            </div>

                            <p
                                class="monitoring__item--desc"
                                v-html="server.description"></p>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {Link} from '@inertiajs/vue3';
import {declOfNum} from '@/Methods/declOfNum.js';

export default {
    name: 'MonitoringComponent',

    components: {
        Link,
    },

    props: {
        servers: Object,
        single: Boolean,
        color: String,
    },

    data() {
        return {
            totalOnline: this.getTotalOnline(),
        };
    },

    methods: {
        getTotalOnline() {
            return this.servers.reduce(
                (sum, item) => sum + parseInt(item.online),
                0,
            );
        },
        declOfNum: declOfNum,
    },
};
</script>
