<template>
    <div>
        <Head :title="title" />

        <jet-validation-errors v-if="pageType !== 'cabinet'" />
        <jet-banner />

        <header-component></header-component>
        <user-info-component
            v-if="$page.props.user"
            :type="pageType"
            :user="$page.props.user"></user-info-component>
        <info-block-component v-else></info-block-component>

        <main>
            <slot></slot>
        </main>

        <top-votes-component
            v-if="pageType !== 'vote'"
            :main-money="$page.props.mainMoney"
            :main-votes="$page.props.mainVotes"></top-votes-component>

        <section class="third">
            <social-component></social-component>
            <header-component footer></header-component>
        </section>
    </div>
</template>

<script>
import {Head} from '@inertiajs/vue3';
import JetBanner from '@/Jetstream/Banner.vue';
import HeaderComponent from '@/Components/HeaderComponent.vue';
import InfoBlockComponent from '@/Components/InfoBlockComponent.vue';
import UserInfoComponent from '@/Components/UserInfoComponent.vue';
import JetValidationErrors from '@/Jetstream/ValidationErrors.vue';
import TopVotesComponent from '@/Components/Home/TopVotesComponent.vue';
import SocialComponent from '@/Components/SocialComponent.vue';

export default {
    components: {
        Head,
        JetBanner,
        HeaderComponent,
        InfoBlockComponent,
        UserInfoComponent,
        JetValidationErrors,
        TopVotesComponent,
        SocialComponent,
    },
    props: {
        title: String,
    },

    data() {
        return {
            pageType: this.getPageType(),
        };
    },

    created() {
        document.querySelector('body').classList.remove('promo');
        document.querySelector('body').classList.add('main');
    },

    methods: {
        getPageType() {
            switch (this.$page.url) {
                case '/market':
                case '/market/my':
                case '/market/cart':
                case '/market/history':
                    return 'market';
                case '/cabinet':
                    return 'cabinet';
                case '/page/vote':
                    return 'vote';
                default:
                    return;
            }
        },
    },
};
</script>
