<template>
    <span>
        <span @click="startConfirmingPassword">
            <slot />
        </span>

        <modal-component :show="confirmingPassword" @close="closeModal">
            <template #title>
                {{ title }}
            </template>

            <template #content>
                <div class="input-group">
                    <jet-label for="2fa-password" :value="content" />
                    <jet-input
                        id="2fa-password"
                        ref="password"
                        v-model="form.password"
                        class="input-login"
                        placeholder="Введите пароль"
                        type="password"
                        @keyup.enter="confirmPassword" />
                </div>
            </template>

            <template #footer>
                <div class="mt-16">
                    <jet-secondary-button @click="closeModal">
                        {{ close }}
                    </jet-secondary-button>

                    <jet-button
                        :class="{'opacity-25': form.processing}"
                        :disabled="form.processing"
                        class="ml-16"
                        @click="confirmPassword">
                        {{ button }}
                    </jet-button>
                </div>
            </template>
        </modal-component>
    </span>
</template>

<script>
import JetButton from './Button.vue';
import JetInput from './Input.vue';
import JetInputError from './InputError.vue';
import JetSecondaryButton from './SecondaryButton.vue';
import ModalComponent from '@/Components/Reusable/ModalComponent.vue';
import JetLabel from '@/Jetstream/Label.vue';

export default {
    components: {
        JetButton,
        ModalComponent,
        JetInput,
        JetInputError,
        JetSecondaryButton,
        JetLabel,
    },

    props: {
        title: {
            default: 'Подтвердите пароль',
        },
        content: {
            default:
                'В целях вашей безопасности, пожалуйста, подтвердите свой пароль, чтобы продолжить.',
        },
        button: {
            default: 'Подтвердить',
        },
        close: {
            default: 'Закрыть',
        },
    },
    emits: ['confirmed'],

    data() {
        return {
            confirmingPassword: false,
            form: this.$inertia.form({
                password: '',
                error: '',
            }),
        };
    },

    methods: {
        startConfirmingPassword() {
            axios.get(route('password.confirmation')).then(response => {
                if (response.data.confirmed) {
                    this.$emit('confirmed');
                } else {
                    this.confirmingPassword = true;

                    setTimeout(() => this.$refs.password.focus(), 250);
                }
            });
        },

        confirmPassword() {
            this.form.processing = true;

            axios
                .post(route('password.fortify.confirm'), {
                    password: this.form.password,
                })
                .then(() => {
                    this.form.processing = false;
                    this.closeModal();
                    this.$nextTick(() => this.$emit('confirmed'));
                })
                .catch(error => {
                    this.form.processing = false;
                    this.form.error = error.response.data.errors.password[0];
                    this.$refs.password.focus();
                });
        },

        closeModal() {
            this.confirmingPassword = false;
            this.form.password = '';
            this.form.error = '';
        },
    },
};
</script>
