<template>
    <div class="infoblock">
        <div class="container infoblock__container">
            <div class="infoblock__container--left">
                <h1>Добро пожаловать на HardMine</h1>
                <h2>
                    Мы приготовили для тебя самые лучшие сервера в майнкрафт с
                    модами
                </h2>

                <div class="infoblock__container--left-buttons">
                    <button
                        class="button button--blue"
                        type="button"
                        @click="showRegisterModal = true">
                        Начать играть
                    </button>
                    <button
                        class="button button--pink"
                        type="button"
                        @click="showLoginModal = true">
                        Авторизация
                    </button>
                </div>
            </div>

            <div class="infoblock__container--right">
                <img alt="HardMine" src="/build/images/infoblock__image.webp" />
            </div>
        </div>
    </div>

    <notify-component
        v-if="$page.props.flash.showLoginModal"
        message="Для просмотра страницы необходима авторизация"
        type="error" />

    <login-modal-component
        :show="showLoginModal"
        @close="showLoginModal = false"
        @open-register-modal="openNewModal('register')"
        @open-forget-password-modal="openNewModal('forget-password')" />

    <register-modal-component
        :show="showRegisterModal"
        @close="showRegisterModal = false" />
    <forget-password-modal-component
        :show="showForgetPasswordModal"
        @close="showForgetPasswordModal = false" />
</template>

<script>
import LoginModalComponent from './Modals/LoginModalComponent.vue';
import RegisterModalComponent from './Modals/RegisterModalComponent.vue';
import ForgetPasswordModalComponent from './Modals/ForgetPasswordModalComponent.vue';
import NotifyComponent from './Reusable/NotifyComponent.vue';

export default {
    name: 'InfoBlockComponent',
    components: {
        NotifyComponent,
        RegisterModalComponent,
        LoginModalComponent,
        ForgetPasswordModalComponent,
    },
    data() {
        return {
            showRegisterModal: false,
            showForgetPasswordModal: false,
            showLoginModal: false,
        };
    },
    mounted() {
        if (this.$page.props.flash.showLoginModal) {
            this.showLoginModal = true;
        }

        if (this.$page.props.flash.showRegisterModal) {
            this.showRegisterModal = true;
        }
    },
    methods: {
        openNewModal(type) {
            switch (type) {
                case 'register':
                    this.showForgetPasswordModal = false;
                    this.showLoginModal = false;
                    this.showRegisterModal = true;
                    return;
                case 'login':
                    this.showForgetPasswordModal = false;
                    this.showRegisterModal = false;
                    this.showLoginModal = true;
                    return;
                case 'forget-password':
                    this.showRegisterModal = false;
                    this.showLoginModal = false;
                    this.showForgetPasswordModal = true;
                    return;
            }
        },
    },
};
</script>
