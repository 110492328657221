import '../sass/app.scss';

import {computed, createApp, h} from 'vue';
import {createInertiaApp} from '@inertiajs/vue3';

import Toast from 'vue-toastification';
import VueSmoothScroll from 'vue3-smooth-scroll';
import * as Sentry from '@sentry/vue';

const appName = computed(() => 'HardMine');

createInertiaApp({
    progress: {
        color: '#008BE1',
    },
    title: title => `${title} – ${appName.value}`,
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.vue', {eager: true});
        return pages[`./Pages/${name}.vue`];
    },
    setup({el, App, props, plugin}) {
        const app = createApp({render: () => h(App, props)});
        Sentry.init({
            app,
            dsn: 'https://64f6df727a9730dbe2da88f6756fa7fc@o4506688443645952.ingest.sentry.io/4506688459440128',
            integrations: [
                // new Sentry.BrowserTracing({
                //     // routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                // }),
                // Sentry.replayIntegration(),
            ],
            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
        });

        app.use(plugin)
            .use(Toast, {})
            .use(VueSmoothScroll)
            .mixin({methods: {route}})
            .mount(el);

        return app;
    },
});
