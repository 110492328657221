<template>
    <h4
        class="cabinet__h5"
        v-html="
            twoFactorEnabled
                ? 'Вы&nbsp;включили двухфакторную аутентификацию'
                : 'Вы&nbsp;не&nbsp;включили двухфакторную аутентификацию'
        "></h4>

    <p class="cabinet__p mb-16">
        Когда включена двухфакторная аутентификация, вам будет предложено ввести
        безопасный случайный токен во&nbsp;время идентификации. Вы&nbsp;можете
        получить этот токен из&nbsp;приложения Google Authenticator вашего
        телефона.
    </p>

    <qr-modal-component :show="showModal" @close="close">
        <template #content>
            <div v-if="qrCode">
                <div class="mt-4 max-w-xl text-sm text-gray-600">
                    <p class="cabinet__p mb-16">
                        Двухфакторная аутентификация теперь включена.
                        Отсканируйте следующий QR-код с&nbsp;помощью вашего
                        телефона в&nbsp;Google Authenticator.
                    </p>
                </div>

                <div class="cabinet__qr" v-html="qrCode"></div>
            </div>

            <div v-if="recoveryCodes.length > 0">
                <div class="mt-4 max-w-xl text-sm text-gray-600">
                    <p class="cabinet__p mb-16">
                        Храните эти коды восстановления в&nbsp;безопасном
                        менеджере паролей. Они могут быть использованы для
                        восстановления доступа к&nbsp;вашей учетной записи, если
                        вы&nbsp;потеряли доступ к&nbsp;Google Authenticator.
                    </p>
                </div>

                <div class="cabinet__qr--list">
                    <div v-for="code in recoveryCodes" :key="code">
                        {{ code }}
                    </div>
                </div>
            </div>
        </template>
    </qr-modal-component>

    <div class="mt-5">
        <div v-if="!twoFactorEnabled">
            <jet-confirms-password @confirmed="enableTwoFactorAuthentication">
                <jet-button
                    type="button"
                    :class="{'button--process': enabling}"
                    :disabled="enabling">
                    Включить 2FA
                </jet-button>
            </jet-confirms-password>
        </div>

        <div v-else>
            <!--<jet-confirms-password @confirmed="regenerateRecoveryCodes">
                <jet-secondary-button class="mr-16" v-if="recoveryCodes.length > 0">
                    Восстанавливать коды восстановления
                </jet-secondary-button>
            </jet-confirms-password>

            <jet-confirms-password @confirmed="showRecoveryCodes">
                <jet-secondary-button class="mr-16" v-if="recoveryCodes.length === 0">
                    Показывать коды восстановления
                </jet-secondary-button>
            </jet-confirms-password>-->

            <jet-confirms-password @confirmed="disableTwoFactorAuthentication">
                <jet-danger-button
                    :class="{'opacity-25': disabling}"
                    :disabled="disabling">
                    Выключить 2FA
                </jet-danger-button>
            </jet-confirms-password>
        </div>
    </div>
</template>

<script>
import {defineComponent} from 'vue';
import JetActionSection from '@/Jetstream/ActionSection.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetConfirmsPassword from '@/Jetstream/ConfirmsPassword.vue';
import JetDangerButton from '@/Jetstream/DangerButton.vue';
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue';
import QrModalComponent from '@/Components/Modals/QrModalComponent.vue';

export default defineComponent({
    components: {
        QrModalComponent,
        JetActionSection,
        JetButton,
        JetConfirmsPassword,
        JetDangerButton,
        JetSecondaryButton,
    },

    data() {
        return {
            enabling: false,
            disabling: false,
            showModal: false,

            qrCode: null,
            recoveryCodes: [],
        };
    },

    computed: {
        twoFactorEnabled() {
            return !this.enabling && this.$page.props.two_factor_enabled;
        },
    },

    methods: {
        enableTwoFactorAuthentication() {
            this.enabling = true;

            this.$inertia.post(
                '/user/two-factor-authentication',
                {},
                {
                    preserveScroll: true,
                    onSuccess: () =>
                        Promise.all([
                            this.showQrCode(),
                            this.showRecoveryCodes(),
                            (this.showModal = true),
                        ]),
                    onFinish: () => (this.enabling = false),
                },
            );
        },

        close() {
            this.showModal = false;
        },

        showQrCode() {
            return axios.get('/user/two-factor-qr-code').then(response => {
                this.qrCode = response.data.svg;
            });
        },

        showRecoveryCodes() {
            return axios
                .get('/user/two-factor-recovery-codes')
                .then(response => {
                    this.recoveryCodes = response.data;
                });
        },

        regenerateRecoveryCodes() {
            axios.post('/user/two-factor-recovery-codes').then(response => {
                this.showRecoveryCodes();
            });
        },

        disableTwoFactorAuthentication() {
            this.disabling = true;

            this.$inertia.delete('/user/two-factor-authentication', {
                preserveScroll: true,
                onSuccess: () => (this.disabling = false),
            });
        },
    },
});
</script>
