<template>
    <app-layout title="Наши сервера">
        <monitoring-component
            :servers="$page.props.servers"
            :single="true"
            color="white"></monitoring-component>
    </app-layout>
</template>

<script>
import {Head} from '@inertiajs/vue3';
import AppLayout from '@/Layouts/AppLayout.vue';
import CardsComponent from '@/Components/Home/CardsComponent.vue';
import MonitoringComponent from '@/Components/Home/MonitoringComponent.vue';

export default {
    name: 'Home',
    components: {
        Head,
        AppLayout,
        CardsComponent,
        MonitoringComponent,
    },
};
</script>
