<template>
    <app-layout title="Главная">
        <cards-component></cards-component>
        <monitoring-component
            :servers="$page.props.servers"
            color="dark"></monitoring-component>
        <zombie-apocalypse-component></zombie-apocalypse-component>
        <go-vote-component></go-vote-component>
    </app-layout>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import CardsComponent from '@/Components/Home/CardsComponent.vue';
import MonitoringComponent from '@/Components/Home/MonitoringComponent.vue';
import ZombieApocalypseComponent from '@/Components/Home/ZombieApocalypseComponent.vue';
import GoVoteComponent from '@/Components/GoVoteComponent.vue';

export default {
    name: 'Home',
    components: {
        GoVoteComponent,
        ZombieApocalypseComponent,
        AppLayout,
        CardsComponent,
        MonitoringComponent,
    },
};
</script>
