<template>
    <button :type="type" class="button button--small button--blue">
        <slot></slot>
    </button>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'submit',
        },
    },
};
</script>
