<template>
    <promo-layout title="Промо-страница"> </promo-layout>
</template>

<script>
import PromoLayout from '@/Layouts/PromoLayout.vue';

export default {
    components: {
        PromoLayout,
    },
};
</script>
