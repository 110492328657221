<template>
    <div class="user-info">
        <div class="container user-info__container">
            <div class="user-info__left">
                <Link
                    class="user-info__left--title link"
                    :href="route('profile.view', user.name)"
                    >{{ title }}</Link
                >
                <div class="user-info__table-wrapper">
                    <table class="user-info__table">
                        <thead>
                            <tr>
                                <th scope="col">Сервер</th>
                                <th scope="col">Привилегия</th>
                                <th scope="col">До</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in userGroups">
                                <td>
                                    <Link
                                        :href="route('servers.show', item.slug)"
                                        v-html="item.server"></Link>
                                </td>
                                <td v-html="item.name"></td>
                                <td
                                    v-html="
                                        item.until === null
                                            ? 'Навсегда'
                                            : parseDate(item.until)
                                    "></td>
                            </tr>
                            <tr v-if="!userGroups || userGroups.length === 0">
                                <td colspan="3">
                                    На данный момент у вас нет ни одной
                                    привилегии
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <form @submit.prevent="logout">
                        <button
                            class="button button--secondary text-dark"
                            type="submit">
                            Выйти из аккаунта
                        </button>
                    </form>
                </div>
            </div>
            <div class="user-info__wrapper">
                <div class="user-info__right">
                    <div
                        class="user-info__right--balance"
                        @click="showPaymentModal = true">
                        <payment-modal-component
                            :show="showPaymentModal"
                            @close="showPaymentModal = false" />

                        <div>
                            <img
                                alt="Баланс"
                                src="/build/svg/user-info__money.svg" />
                            <h3>Баланс:</h3>
                            <span>{{ user.money }} ₽</span>

                            <p>Нажмите сюда, чтобы пополнить баланс</p>
                        </div>
                    </div>
                    <!--                    <div v-if="type === 'market'" class="user-info__right&#45;&#45;coins" @click="showCurrencyModal = true">-->
                    <!--                        <currency-modal-component :show="showCurrencyModal" @update="update"-->
                    <!--                                                  @close="showCurrencyModal = false"/>-->

                    <!--                        <div>-->
                    <!--                            <img alt="Баланс коинов" src="/build/images/user-info__coins.svg">-->
                    <!--                            <h3>Сурвайлики:</h3>-->
                    <!--                            <span>-->
                    <!--                                {{ user.coins }} св.-->
                    <!--                                &lt;!&ndash; {{ declOfNum(user.coins, ['сурвайлик', 'сурвайлика', 'сурвайликов']) }} &ndash;&gt;-->
                    <!--                            </span>-->

                    <!--                            <p>Нажмите сюда, чтобы обменять валюту</p>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                    <div
                        class="user-info__right--launcher"
                        @click="
                            currency.success = false;
                            showLauncherModal = true;
                        ">
                        <launcher-modal-component
                            :show="showLauncherModal"
                            @close="showLauncherModal = false" />

                        <div>
                            <img
                                alt="Наш лаунчер"
                                src="/build/svg/user-info__coins.svg" />
                            <h4>Наш лаунчер</h4>
                            <p>Нажмите сюда, чтобы скачать лаунчер</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <notify-component
        v-if="currency.success"
        :message="currency.message"
        type="success" />
</template>

<script>
import LauncherModalComponent from './Modals/LauncherModalComponent.vue';
import PaymentModalComponent from './Modals/PaymentModalComponent.vue';
import CurrencyModalComponent from './Modals/CurrencyModalComponent.vue';
import {router} from '@inertiajs/vue3';
import NotifyComponent from './Reusable/NotifyComponent.vue';
import {Link} from '@inertiajs/vue3';
import {parseDate} from '@/Methods/parseDate.js';

export default {
    name: 'UserInfoComponent',
    components: {
        LauncherModalComponent,
        PaymentModalComponent,
        CurrencyModalComponent,
        NotifyComponent,
        Link,
    },
    props: ['user', 'type'],
    data() {
        return {
            showLauncherModal: false,
            showPaymentModal: false,
            showCurrencyModal: false,
            title: this.getTitle(),
            currency: {
                success: false,
                message: '',
            },
            userGroups: this.$page.props.userGroups,
        };
    },
    methods: {
        getTitle() {
            switch (this.type) {
                case 'market':
                    return 'Рынок Zombie Apocalypse';
                case 'cabinet':
                    return `Профиль ${this.user.name}`;
                default:
                    return `Привет, ${this.user.name}!`;
            }
        },
        logout() {
            this.$inertia.post(route('logout'));
        },
        update(message) {
            this.currency.success = true;
            this.currency.message = message;

            router.reload();
        },
        parseDate: parseDate,
    },
};
</script>
