<template>
    <div class="table-wrapper">
        <div class="table-responsive">
            <table class="table table--donate table-hover">
                <thead>
                    <tr>
                        <td>Разное</td>
                        <td class="vip">VIP</td>
                        <td class="premium">PRM</td>
                        <td class="platinum">PLT</td>
                        <td class="god">GOD</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Премиум доступ в боевом пропуске</td>
                        <td></td>
                        <td></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="table-wrapper">
        <div class="table-responsive">
            <table class="table table--donate table-hover">
                <thead>
                    <tr>
                        <td>Приваты и точки /home</td>
                        <td class="vip">VIP</td>
                        <td class="premium">PRM</td>
                        <td class="platinum">PLT</td>
                        <td class="god">GOD</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Размер 1 привата</td>
                        <td>400к</td>
                        <td>400к</td>
                        <td>400к</td>
                        <td>400к</td>
                    </tr>
                    <tr>
                        <td>Количество приватов</td>
                        <td>4</td>
                        <td>5</td>
                        <td>8</td>
                        <td>10</td>
                    </tr>
                    <tr>
                        <td>Количество точек /home</td>
                        <td>2</td>
                        <td>3</td>
                        <td>5</td>
                        <td>7</td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component :tooltip="'Течение воды'">
                                Флаг <b>water-flow</b>
                                <i class="icon icon-question ml-3"></i>
                            </large-tooltip-component>
                        </td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component :tooltip="'Течение лавы'">
                                Флаг <b>lava-flow</b>
                                <i class="icon icon-question ml-3"></i>
                            </large-tooltip-component>
                        </td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component :tooltip="'Спавн мобов'">
                                Флаг <b>mob-spawning</b>
                                <i class="icon icon-question ml-3"></i>
                            </large-tooltip-component>
                        </td>
                        <td></td>
                        <td></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component :tooltip="'Запрещает вход посторонним игрокам'">
                                Флаг <b>entry</b>
                                <i class="icon icon-question ml-3"></i>
                            </large-tooltip-component>
                        </td>
                        <td></td>
                        <td></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component :tooltip="'Урон от мобов'">
                                Флаг <b>mob-damage</b>
                                <i class="icon icon-question ml-3"></i>
                            </large-tooltip-component>
                        </td>
                        <td></td>
                        <td></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component
                                :tooltip="'Урон от ведьмы'">
                                Флаг <b>wither-damage</b>
                                <i class="icon icon-question ml-3"></i>
                            </large-tooltip-component>
                        </td>
                        <td></td>
                        <td></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component
                                :tooltip="'Приветственное сообщение при входе'">
                                Флаг <b>greeting</b>
                                <i class="icon icon-question ml-3"></i>
                            </large-tooltip-component>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component
                                :tooltip="'Опадение листвы'">
                                Флаг <b>leaf-decay</b>
                                <i class="icon icon-question ml-3"></i>
                            </large-tooltip-component>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component
                                :tooltip="'Появление снега'">
                                Флаг <b>snow-fall</b>
                                <i class="icon icon-question ml-3"></i>
                            </large-tooltip-component>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component
                                :tooltip="'Формирование льда'">
                                Флаг <b>ice-form</b>
                                <i class="icon icon-question ml-3"></i>
                            </large-tooltip-component>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="table-wrapper">
        <div class="table-responsive">
            <table class="table table--donate table-hover">
                <thead>
                    <tr>
                        <td>Дополнительные команды</td>
                        <td class="vip">VIP</td>
                        <td class="premium">PRM</td>
                        <td class="platinum">PLT</td>
                        <td class="god">GOD</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Отключение кика за <b>AFK</b></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Возвращение назад <b>/back</b></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Возможность утолить голод <b>/feed</b></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Режим полёта <b>/fly</b></td>
                        <td></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Починка брони и инструментов<b>/repair</b></td>
                        <td></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Вход на заполненный сервер</td>
                        <td></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Режим бога <b>/god</b></td>
                        <td></td>
                        <td></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Восстановление здоровья <b>/heal</b></td>
                        <td></td>
                        <td></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Очистка инвентаря <b>/clear</b></td>
                        <td></td>
                        <td></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Переносной верстак <b>/workbench</b></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Переносной эндерсундук <b>/enderchest</b></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Игроки поблизости <b>/near</b></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Отображение скрытых игроков <b>/list</b></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component
                                :tooltip="'Изменение происходит только для игрока'">
                                Изменение времени суток <b>/ptime</b>
                                <i class="icon icon-question ml-3"></i>
                            </large-tooltip-component>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component
                                :tooltip="'Изменение происходит только для игрока'">
                                Изменение погоды <b>/pweather</b>
                                <i class="icon icon-question ml-3"></i>
                            </large-tooltip-component>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="table-wrapper">
        <div class="table-responsive">
            <table class="table table--donate table-hover">
                <thead>
                    <tr>
                        <td>Наборы</td>
                        <td class="vip">VIP</td>
                        <td class="premium">PRM</td>
                        <td class="platinum">PLT</td>
                        <td class="god">GOD</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <large-tooltip-component
                                :clickable="true"
                                content="Нажмите, чтобы узнать подробнее"
                                @click.prevent="showKits = true">
                                Kit <b>Start</b>
                                <i class="icon icon-question ml-3"></i>
                            </large-tooltip-component>
                            <img
                                v-if="isKitsAvailable && parsedKits.length >= 1"
                                :alt="parsedKits[0].text"
                                :src="parsedKits[0].url"
                                class="server-image mt-8" />
                        </td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component
                                :clickable="true"
                                content="Нажмите, чтобы узнать подробнее"
                                @click.prevent="showKits = true">
                                Kit <b>Vip</b>
                                <i class="icon icon-question ml-3"></i>
                            </large-tooltip-component>
                            <img
                                v-if="isKitsAvailable && parsedKits.length >= 2"
                                :alt="parsedKits[1].text"
                                :src="parsedKits[1].url"
                                class="server-image mt-8" />
                        </td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component
                                :clickable="true"
                                content="Нажмите, чтобы узнать подробнее"
                                @click.prevent="showKits = true">
                                Kit <b>Premium</b>
                                <i class="icon icon-question ml-3"></i>
                            </large-tooltip-component>
                            <img
                                v-if="isKitsAvailable && parsedKits.length >= 3"
                                :alt="parsedKits[2].text"
                                :src="parsedKits[2].url"
                                class="server-image mt-8" />
                        </td>
                        <td></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component
                                :clickable="true"
                                content="Нажмите, чтобы узнать подробнее"
                                @click.prevent="showKits = true">
                                Kit <b>Platinum</b>
                                <i class="icon icon-question ml-3"></i>
                            </large-tooltip-component>
                            <img
                                v-if="isKitsAvailable && parsedKits.length >= 4"
                                :alt="parsedKits[3].text"
                                :src="parsedKits[3].url"
                                class="server-image mt-8" />
                        </td>
                        <td></td>
                        <td></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component
                                :clickable="true"
                                content="Нажмите, чтобы узнать подробнее"
                                @click.prevent="showKits = true">
                                Kit <b>God</b>
                                <i class="icon icon-question ml-3"></i>
                            </large-tooltip-component>
                            <img
                                v-if="isKitsAvailable && parsedKits.length >= 5"
                                :alt="parsedKits[4].text"
                                :src="parsedKits[4].url"
                                class="server-image mt-8" />
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <kits-modal-component
        :show="showKits"
        :kits="kits"
        :server="server"
        @close="showKits = false"></kits-modal-component>
</template>

<script>
import LargeTooltipComponent from '@/Components/Reusable/LargeTooltipComponent.vue';
import KitsModalComponent from '@/Components/Modals/KitsModalComponent.vue';

export default {
    components: {KitsModalComponent, LargeTooltipComponent},
    props: ['kits', 'server'],
    data() {
        return {
            showKits: false,
        };
    },
    computed: {
        parsedKits() {
            return JSON.parse(this.kits);
        },
    },
    methods: {
        isKitsAvailable() {
            return this.kits && JSON.parse(this.kits).length > 0;
        },
    },
};
</script>
