<template>
    <modal-component :show="show" @close="close">
        <template #title> Киты сервера {{ server }} </template>
        <template #content>
            <div v-if="kits && JSON.parse(kits)?.length > 0">
                <div
                    v-for="(link, key) in JSON.parse(kits)"
                    class="server-kits">
                    <div>
                        <span v-html="link.text"></span>
                        <img
                            :alt="'Image #' + (key + 1)"
                            :src="link.url"
                            class="server-image" />
                    </div>
                </div>
            </div>
        </template>
        <template #close>
            <button class="modal__close" type="button" @click="close()">
                <close-modal-icon />
            </button>
        </template>
    </modal-component>
</template>

<script>
import ModalComponent from '@/Components/Reusable/ModalComponent.vue';
import CloseModalIcon from '@/Components/Reusable/CloseModalIcon.vue';

export default {
    components: {
        ModalComponent,
        CloseModalIcon,
    },
    props: ['show', 'kits', 'server'],
    emits: ['close'],
    data() {
        return {};
    },
    methods: {
        close() {
            this.$emit('close');
        },
    },
};
</script>
