<template>
    <Index current="cart">
        <template #alert>
            <alert-component :rounded="true" type="info">
                Этот раздел представляет собой корзину покупок, в которую
                перемещаются купленные вами предметы из админшопа и рынка. Чтобы
                получить предмет в игре, зайдите на сервер и введите команду
                «/shop cart»
            </alert-component>
        </template>

        <template #main>
            <h3 class="text-h2 text-dark">Корзина</h3>

            <market-table :items="items" />
            <pagination-component
                :count="items.total"
                :links="items.links"
                :market="true" />
        </template>
    </Index>
</template>

<script>
import Index from '@/Pages/Market/Index.vue';
import AlertComponent from '@/Components/Reusable/AlertComponent.vue';
import PaginationComponent from '@/Components/PaginationComponent.vue';
import MarketTable from './MarketTable.vue';

export default {
    name: 'Items',
    components: {
        MarketTable,
        Index,
        AlertComponent,
        PaginationComponent,
    },
    props: {
        items: Object,
    },
};
</script>
