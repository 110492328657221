<template>
    <section class="shop-info">
        <div class="container">
            <h3 class="text-title text-shop">Магазин</h3>
            <h4 class="text-subtitle text-shop">
                Выберите раздел, который вам нужен
            </h4>

            <div class="shop-info__wrapper">
                <div class="shop-info__cards">
                    <a
                        class="shop-info__cards--item image"
                        href="#"
                        @click.prevent="showShopModal = true">
                        <div class="shop-info__cards--item--image">
                            <img
                                alt=""
                                src="/build/images/shop-info__image-1.webp" />
                            <span>Подробнее...</span>
                        </div>
                        <h5>Магазин Блоков</h5>
                        <p>
                            Тут можно купить любой блок, который необходим вам в
                            игре.
                        </p>
                    </a>

                    <Link
                        :href="route('donate')"
                        class="shop-info__cards--item image">
                        <div class="shop-info__cards--item--image">
                            <img
                                alt=""
                                src="/build/images/shop-info__image-2.webp" />
                            <span>Подробнее...</span>
                        </div>
                        <h5>Покупка привилегий</h5>
                        <p>
                            Тут можно купить один из уникальных статусов,
                            который дает кучу возможностей.
                        </p>
                    </Link>

                    <a
                        class="shop-info__cards--item image"
                        href="#"
                        @click.prevent="showCaseModal = true">
                        <div class="shop-info__cards--item--image">
                            <img
                                alt=""
                                src="/build/images/shop-info__image-3.webp" />
                            <span>Подробнее...</span>
                        </div>
                        <h5>Магазин Кейсов</h5>
                        <p>
                            Тут можно купить кейс и получить из него рандомный
                            предмет в игре.
                        </p>
                    </a>
                </div>
            </div>
        </div>
    </section>

    <shop-modal-component
        :show="showShopModal"
        @close="showShopModal = false"></shop-modal-component>
    <case-modal-component
        :show="showCaseModal"
        @close="showCaseModal = false"></case-modal-component>
</template>

<script>
import {Link} from '@inertiajs/vue3';
import ShopModalComponent from '@/Components/Modals/ShopModalComponent.vue';
import CaseModalComponent from '../Modals/CaseModalComponent.vue';

export default {
    name: 'CardsComponent',
    components: {
        CaseModalComponent,
        ShopModalComponent,
        Link,
    },
    data() {
        return {
            showShopModal: false,
            showCaseModal: false,
        };
    },
};
</script>

<style scoped></style>
