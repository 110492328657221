<template>
    <section class="features">
        <div class="container">
            <h3 class="text-dark text-title">Почему мы?</h3>

            <div class="features__grid">
                <div
                    v-for="(item, index) in items"
                    :key="index"
                    class="features__grid--item">
                    <img
                        :alt="item.title"
                        :src="'/build/images/' + item.image"
                        class="features__grid--item-image" />
                    <span
                        class="features__grid--item-span"
                        v-html="item.title"></span>
                    <p
                        class="features__grid--item-desc"
                        v-html="item.subtitle"></p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'FeaturesComponent',
    data() {
        return {
            items: [
                {
                    title: 'Проработанные сборки',
                    subtitle:
                        'Наши сборки детально проработаны, оптимизированы, избавлены от дюпов и крашей',
                    image: 'features--1.webp',
                },
                {
                    title: 'Мощный хостинг',
                    subtitle:
                        'Чтобы получить максимальную производительность и высокий tps мы арендуем мощные выделенные сервера в Германии',
                    image: 'features--2.webp',
                },
                {
                    title: 'Бонусы за голосование',
                    subtitle:
                        'Ежедневные кейсы за голосование на мониторингах, привилегии за буст в дискорде и многое другое',
                    image: 'features--3.webp',
                },
                {
                    title: 'Скины, плащи, кейсы и донат',
                    subtitle:
                        'Любой игрок может поставить себе скин или плащ, а кейсы и донат помогут в развитии на серверах',
                    image: 'features--4.webp',
                },
                {
                    title: 'Уникальные ивенты',
                    subtitle:
                        'Раз в неделю на серверах проводятся различные ивенты с игровыми и донатными призами',
                    image: 'features--5.webp',
                },
                {
                    title: 'Общительное комьюнити',
                    subtitle:
                        'В нашей группе VK и Discord сервере вы всегда сможете найти себе тиммейта, а также пообщаться с игроками на разные темы',
                    image: 'features--6.webp',
                },
            ],
        };
    },
};
</script>
