<template>
    <app-layout title="Рынок">
        <div class="container">
            <section class="market section">
                <div class="market__container">
                    <Menu :current="current"></Menu>

                    <slot name="alert"></slot>

                    <slot name="main"></slot>
                </div>
            </section>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import Menu from './Menu.vue';

export default {
    components: {
        Menu,
        AppLayout,
    },
    props: {
        current: String,
    },
};
</script>
