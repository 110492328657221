<template>
    <div class="table-wrapper">
        <div class="table-responsive">
            <table class="table table--donate table-hover">
                <thead>
                    <tr>
                        <td>Приваты и точки /home</td>
                        <td class="player">Игрок</td>
                        <td class="vip">VIP</td>
                        <td class="god">GOD</td>
                        <td class="creative">CREATIVE</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Размер 1 привата</td>
                        <td>200к</td>
                        <td>200к</td>
                        <td>200к</td>
                        <td>200к</td>
                    </tr>
                    <tr>
                        <td>Количество приватов</td>
                        <td>2</td>
                        <td>4</td>
                        <td>10</td>
                        <td>25</td>
                    </tr>
                    <tr>
                        <td>Количество точек /home</td>
                        <td>1</td>
                        <td>3</td>
                        <td>10</td>
                        <td>25</td>
                    </tr>
                    <tr>
                        <td>Доступ более чем к <b>50</b> флагам</td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="table-wrapper">
        <div class="table-responsive">
            <table class="table table--donate table-hover">
                <thead>
                    <tr>
                        <td>Дополнительные команды</td>
                        <td class="player">Игрок</td>
                        <td class="vip">VIP</td>
                        <td class="god">GOD</td>
                        <td class="creative">CREATIVE</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Самоуничтожение <b>/suicide</b></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Узнать рецепт <b>/reciepe</b></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Игроки поблизости <b>/near</b></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Вход на заполненный сервер</td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Восстановление здоровья <b>/heal</b></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Возвращение назад <b>/back</b></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Завершить книгу <b>/book</b></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Переносной верстак <b>/workbench</b></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Починка брони и инструментов <b>/repair</b></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component
                                :tooltip="'Изменение происходит только для игрока'">
                                Изменение времени суток <b>/ptime</b>
                                <i class="icon icon-question ml-3"></i>
                            </large-tooltip-component>
                        </td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component
                                :tooltip="'Изменение происходит только для игрока'">
                                Изменение погоды <b>/pweather</b>
                                <i class="icon icon-question ml-3"></i>
                            </large-tooltip-component>
                        </td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component
                                :tooltip="'Подробнее - /kit Color'">
                                Цветной текст
                                <i class="icon icon-question ml-3"></i>
                            </large-tooltip-component>
                        </td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Неуязвимость <b>/god</b></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Измененить описание предмета <b>/itemlore</b></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Режим полёта <b>/fly</b></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Творческий режим <b>/gm 1</b></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component
                                :tooltip="'Изменение происходит для всех игроков'">
                                Изменение погоды <b>/weather</b>
                                <i class="icon icon-question ml-3"></i>
                            </large-tooltip-component>
                        </td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component
                                :tooltip="'Изменение происходит для всех игроков'">
                                Изменение времени суток <b>/time</b>
                                <i class="icon icon-question ml-3"></i>
                            </large-tooltip-component>
                        </td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Телепорт по координатам <b>/tppos</b></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Скорость бега/полёта <b>/speed</b></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Изменить никнейм <b>/nick</b></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Отображение скрытых игроков <b>/list</b></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Зачаровать предмет <b>/enchant</b></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Установить варп <b>/setwarp</b></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>Объявление в чате <b>/bc</b></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="table-wrapper">
        <div class="table-responsive">
            <table class="table table--donate table-hover">
                <thead>
                    <tr>
                        <td>Наборы</td>
                        <td class="player">Игрок</td>
                        <td class="vip">VIP</td>
                        <td class="god">GOD</td>
                        <td class="creative">CREATIVE</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <large-tooltip-component
                                :clickable="true"
                                content="Нажмите, чтобы узнать подробнее"
                                @click.prevent="showKits = true">
                                Kit <b>Start</b>
                                <i class="icon icon-question ml-3"></i>
                            </large-tooltip-component>
                            <img
                                v-if="isKitsAvailable && parsedKits.length >= 1"
                                :alt="parsedKits[0].text"
                                :src="parsedKits[0].url"
                                class="server-image mt-8" />
                        </td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component
                                :clickable="true"
                                content="Нажмите, чтобы узнать подробнее"
                                @click.prevent="showKits = true">
                                Kit <b>Vip</b
                                ><i class="icon icon-question ml-3"></i>
                            </large-tooltip-component>
                            <img
                                v-if="isKitsAvailable && parsedKits.length >= 2"
                                :alt="parsedKits[1].text"
                                :src="parsedKits[1].url"
                                class="server-image mt-8" />
                        </td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component
                                :clickable="true"
                                content="Нажмите, чтобы узнать подробнее"
                                @click.prevent="showKits = true">
                                Kit <b>God</b
                                ><i class="icon icon-question ml-3"></i>
                            </large-tooltip-component>
                            <img
                                v-if="isKitsAvailable && parsedKits.length >= 3"
                                :alt="parsedKits[2].text"
                                :src="parsedKits[2].url"
                                class="server-image mt-8" />
                        </td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <large-tooltip-component
                                :clickable="true"
                                content="Нажмите, чтобы узнать подробнее"
                                @click.prevent="showKits = true">
                                Kit <b>Creative</b
                                ><i class="icon icon-question ml-3"></i>
                            </large-tooltip-component>
                            <img
                                v-if="isKitsAvailable && parsedKits.length >= 4"
                                :alt="parsedKits[3].text"
                                :src="parsedKits[3].url"
                                class="server-image mt-8" />
                        </td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-ban"></i></td>
                        <td><i class="icon-check"></i></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <kits-modal-component
        :show="showKits"
        :kits="kits"
        :server="server"
        @close="showKits = false"></kits-modal-component>
</template>

<script>
import LargeTooltipComponent from '@/Components/Reusable/LargeTooltipComponent.vue';
import KitsModalComponent from '@/Components/Modals/KitsModalComponent.vue';

export default {
    components: {KitsModalComponent, LargeTooltipComponent},
    props: ['kits', 'server'],
    data() {
        return {
            showKits: false,
        };
    },
    computed: {
        parsedKits() {
            return JSON.parse(this.kits);
        },
    },
    methods: {
        isKitsAvailable() {
            return this.kits && JSON.parse(this.kits).length > 0;
        },
    },
};
</script>
