<template>
    <Index current="my">
        <template #alert>
            <alert-component :rounded="true" type="info">
                В этом разделе Вы можете посмотреть выставленные вами на продажу
                предметы
            </alert-component>
        </template>

        <template #main>
            <h3 class="text-h2 text-dark">Мои товары</h3>

            <market-table :items="items" :show-price="true"></market-table>
            <pagination-component
                :count="items.total"
                :links="items.links"
                :market="true" />
        </template>
    </Index>
</template>

<script>
import Index from '@/Pages/Market/Index.vue';
import AlertComponent from '@/Components/Reusable/AlertComponent.vue';
import PaginationComponent from '@/Components/PaginationComponent.vue';
import TooltipComponent from '@/Components/Reusable/TooltipComponent.vue';
import {Link} from '@inertiajs/vue3';
import AppLayout from '@/Layouts/AppLayout.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetLabel from '@/Jetstream/Label.vue';
import NotifyComponent from '@/Components/Reusable/NotifyComponent.vue';
import {parseDate} from '@/Methods/parseDate.js';
import MarketTable from './MarketTable.vue';

export default {
    name: 'Items',
    components: {
        MarketTable,
        Index,
        AppLayout,
        JetInput,
        JetLabel,
        NotifyComponent,
        AlertComponent,
        PaginationComponent,
        TooltipComponent,
        Link,
    },
    props: {
        items: Object,
    },
    methods: {
        parseDate: parseDate,
    },
};
</script>
