<template>
    <modal-component :show="show" @close="close">
        <template #title> Обмен валюты </template>
        <template #content>
            <div class="payment">
                <notify-component
                    v-if="error"
                    :message="message"
                    type="error" />

                <p>
                    Сурвайлики &mdash; это валюта для торговли с игроками на
                    наших игровых серверах, 1 рубль =
                    <b>{{ $page.props.exchange_currency }} св.</b> Пополняйте
                    баланс и становитесь круче на любимом сервере! :)
                </p>
                <form
                    class="login-form"
                    name="login-form"
                    @submit.prevent="submit">
                    <div class="input-group">
                        <jet-label
                            for="cash"
                            value="Сколько рублей перевести?" />
                        <jet-input
                            id="cash"
                            v-model="form.cash"
                            class="input-money"
                            max="10000"
                            min="10"
                            placeholder="Введите количество"
                            required
                            type="number" />
                    </div>

                    <button
                        :class="{'button--process': form.processing}"
                        :disabled="form.processing"
                        class="button button--blue payment-button"
                        type="submit">
                        получить
                        {{ $page.props.exchange_currency * form.cash }} св.
                    </button>
                </form>
            </div>
        </template>
        <template #close>
            <button class="modal__close" type="button" @click="close()">
                <close-modal-icon />
            </button>
        </template>
    </modal-component>
</template>

<script>
import ModalComponent from '@/Components/Reusable/ModalComponent.vue';

import JetButton from '@/Jetstream/Button.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import JetLabel from '@/Jetstream/Label.vue';
import AlertComponent from '@/Components/Reusable/AlertComponent.vue';
import CloseModalIcon from '@/Components/Reusable/CloseModalIcon.vue';
import NotifyComponent from '@/Components/Reusable/NotifyComponent.vue';
import axios from 'axios';

export default {
    name: 'PaymentModalComponent',
    components: {
        NotifyComponent,
        AlertComponent,
        ModalComponent,
        JetButton,
        JetInput,
        JetCheckbox,
        JetLabel,
        CloseModalIcon,
    },
    props: ['show'],
    emits: ['close', 'update'],
    data() {
        return {
            form: this.$inertia.form({
                cash: 10,
            }),
            error: false,
            message: '',
        };
    },
    methods: {
        close() {
            this.$emit('close');
        },
        submit() {
            this.error = false;

            axios
                .post(this.route('market.exchange'), this.form)
                .then(response => {
                    if (response.data.success !== true) {
                        this.error = true;
                        this.message = response.data.message;
                        this.form.recentlySuccessful = false;
                        return;
                    }

                    this.error = false;
                    this.$emit('update', response.data.message);
                    this.close();
                })
                .catch(error => {
                    this.error = true;
                    this.form.recentlySuccessful = false;
                    this.message =
                        'Ошибки при выполнении запроса. Попробуйте еще раз';
                });
        },
    },
};
</script>
