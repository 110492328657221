<template>
    <div v-if="hasErrors">
        <div v-for="(error, key) in errors" :key="key">
            <notify-component :message="error" type="error"></notify-component>
        </div>
    </div>
</template>

<script>
import NotifyComponent from '@/Components/Reusable/NotifyComponent.vue';

export default {
    components: {
        NotifyComponent,
    },
    computed: {
        errors() {
            return this.$page.props.errors;
        },

        hasErrors() {
            return Object.keys(this.errors).length > 0;
        },
    },
    methods: {},
};
</script>
