<template>
    <modal-component :show="show" @close="close">
        <template #title> 2FA авторизация </template>
        <template #content>
            <slot name="content"></slot>
        </template>

        <template #close>
            <button class="modal__close" type="button" @click="close()">
                <close-modal-icon />
            </button>
        </template>
    </modal-component>
</template>

<script>
import ModalComponent from '@/Components/Reusable/ModalComponent.vue';

import JetButton from '@/Jetstream/Button.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import JetLabel from '@/Jetstream/Label.vue';
import NotifyComponent from '@/Components/Reusable/NotifyComponent.vue';
import CloseModalIcon from '@/Components/Reusable/CloseModalIcon.vue';

export default {
    components: {
        ModalComponent,
        JetButton,
        JetInput,
        JetCheckbox,
        JetLabel,
        NotifyComponent,
        CloseModalIcon,
    },
    props: ['show', 'status'],
    emits: ['close'],
    data() {
        return {};
    },
    methods: {
        close() {
            this.$emit('close');
        },
    },
};
</script>
