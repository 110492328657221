<template>
    <app-layout title="Восстановление пароля">
        <modal-component :show="true" @close="close">
            <template #title> Восстановление пароля </template>

            <jet-validation-errors class="mb-4" />
            <template #content>
                <form @submit.prevent="submit">
                    <div class="input-group mb-16">
                        <jet-label for="email" value="Почта" />
                        <jet-input
                            id="email"
                            v-model="form.email"
                            autofocus
                            class="input-login"
                            required
                            type="email" />
                    </div>

                    <div class="input-group mb-16">
                        <jet-label for="password" value="Новый пароль" />
                        <jet-input
                            id="password"
                            v-model="form.password"
                            autocomplete="new-password"
                            class="input-login"
                            required
                            type="password" />
                    </div>

                    <div class="input-group mb-16">
                        <jet-label
                            for="password_confirmation"
                            value="Новый пароль" />
                        <jet-input
                            id="password_confirmation"
                            v-model="form.password_confirmation"
                            autocomplete="new-password"
                            class="input-login"
                            required
                            type="password" />
                    </div>

                    <div class="flex items-center justify-end mt-4">
                        <jet-button
                            :class="{'opacity-25': form.processing}"
                            :disabled="form.processing">
                            Восстановить пароль
                        </jet-button>
                    </div>
                </form>
            </template>
        </modal-component>
    </app-layout>
</template>

<script>
import JetAuthenticationCardLogo from '@/Jetstream/AuthenticationCardLogo.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetLabel from '@/Jetstream/Label.vue';
import JetValidationErrors from '@/Jetstream/ValidationErrors.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import ModalComponent from '@/Components/Reusable/ModalComponent.vue';

export default {
    components: {
        JetAuthenticationCardLogo,
        JetButton,
        JetInput,
        JetLabel,
        JetValidationErrors,
        AppLayout,
        ModalComponent,
    },

    props: {
        email: String,
        token: String,
    },

    data() {
        return {
            form: this.$inertia.form({
                token: this.token,
                email: this.email,
                password: '',
                password_confirmation: '',
            }),
        };
    },

    methods: {
        submit() {
            this.form.post(this.route('password.update'), {
                onFinish: () =>
                    this.form.reset('password', 'password_confirmation'),
            });
        },
    },
};
</script>
