<template>
    <table class="table table-small">
        <thead>
            <tr>
                <td>Дата</td>
                <td>Действие</td>
            </tr>
        </thead>
        <tbody>
            <tr
                v-for="(action, key) in actions"
                v-if="actions.length > 0"
                :key="key">
                <td v-html="parseDate(action.created_at)"></td>
                <td v-html="action.description"></td>
            </tr>
            <tr v-else>
                <td>–</td>
                <td>На данный момент вы не совершили ни одной покупки.</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import {parseDate} from '@/Methods/parseDate.js';

export default {
    name: 'History',
    props: {
        actions: {},
    },
    methods: {
        parseDate: parseDate,
    },
};
</script>
