<template>
    <modal-component :show="show" @close="close">
        <template #title>
            Пополнить баланс игрока {{ $page.props.user.name }}
        </template>
        <template #content>
            <div class="payment">
                <notify-component
                    v-if="error"
                    :message="message"
                    type="error"></notify-component>

                <form
                    class="payment-form"
                    name="login-form"
                    @submit.prevent="submit">
                    <h3>Выберите платежную систему</h3>
                    <div class="modal-donate__types payment-buttons">
                        <div
                            class="modal-donate__types--item"
                            :class="{active: form.cashbox === 'centapp'}"
                            @click.prevent="
                                form.cashbox = 'centapp';
                                form.method = null;
                            ">
                            <span>CentApp</span>
                            <p>
                                Подходит для оплаты<br />
                                картой/СБП
                            </p>
                        </div>
                        <div
                            class="modal-donate__types--item"
                            :class="{active: isCrypto()}"
                            @click.prevent="
                                form.cashbox = 'anypay';
                                form.method = Object.keys(cryptos)[0];
                            ">
                            <span>AnyPay</span>
                            <p>
                                Подходит для оплаты<br />
                                картой/криптой
                            </p>
                        </div>
                        <div
                            class="modal-donate__types--item"
                            :class="{active: form.method === 'qiwi'}"
                            @click.prevent="
                                form.cashbox = 'anypay';
                                form.method = 'qiwi';
                            ">
                            <span>Qiwi</span>
                            <p>
                                Пополнение минимум<br />
                                от 100 ₽
                            </p>
                        </div>
                        <div
                            class="modal-donate__types--item"
                            :class="{active: form.method === 'ym'}"
                            @click.prevent="
                                form.cashbox = 'anypay';
                                form.method = 'ym';
                            ">
                            <span>YooMoney</span>
                            <p>
                                Пополнение минимум<br />
                                от 100 ₽
                            </p>
                        </div>
                    </div>

                    <div v-if="isCrypto()" class="input-group">
                        <jet-label for="cash" value="Криптовалюта" />
                        <select
                            id="method"
                            v-model="form.method"
                            name="method"
                            class="input input-select"
                            required>
                            <option
                                v-for="(item, key) in cryptos"
                                :key="key"
                                :value="key"
                                v-html="item.name" />
                        </select>
                    </div>

                    <div class="input-group">
                        <jet-label
                            for="cash"
                            value="Сумма пополнения в рублях" />
                        <jet-input
                            id="cash"
                            v-model="form.cash"
                            class="input-money"
                            max="10000"
                            :min="min"
                            required
                            type="number" />
                    </div>

                    <button
                        :class="{'button--process': form.processing}"
                        :disabled="form.processing"
                        class="button button--blue payment-button"
                        type="submit">
                        Пополнить баланс на {{ form.cash }} ₽
                    </button>

                    <p class="payment-disclaimer text-dark">
                        Вы&nbsp;делаете добровольное пожертвование
                        на&nbsp;развитие проекта. Переведённые средства вернуть
                        нельзя. При возникновении проблем обращайтесь
                        к&nbsp;<Link :href="route('contacts')" target="_blank"
                            >администратору</Link
                        >
                    </p>
                </form>
            </div>
        </template>
        <template #close>
            <button class="modal__close" type="button" @click="close()">
                <close-modal-icon />
            </button>
        </template>
    </modal-component>
</template>

<script>
import ModalComponent from '@/Components/Reusable/ModalComponent.vue';

import JetButton from '@/Jetstream/Button.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import JetLabel from '@/Jetstream/Label.vue';
import AlertComponent from '@/Components/Reusable/AlertComponent.vue';
import CloseModalIcon from '@/Components/Reusable/CloseModalIcon.vue';
import NotifyComponent from '@/Components/Reusable/NotifyComponent.vue';
import {Link} from '@inertiajs/vue3';
import axios from 'axios';

export default {
    name: 'PaymentModalComponent',
    components: {
        NotifyComponent,
        AlertComponent,
        ModalComponent,
        JetButton,
        JetInput,
        JetCheckbox,
        JetLabel,
        CloseModalIcon,
        Link,
    },
    props: ['show'],
    emits: ['close'],
    data() {
        return {
            error: false,
            message: '',
            form: this.$inertia.form({
                cash: 15,
                cashbox: 'centapp',
                method: null,
            }),
            cryptos: {
                card: {name: 'Банк. карта (RUB, UAH, BYN, KZT)'},
                // qiwi: {nane: 'Qiwi'},
                // sbp: {name: "СБП"},
                advcash: {name: 'Advcash'},
                pm: {name: 'Perfect Money'},
                btc: {name: 'Bitcoin'},
                ltc: {name: 'Litecoin'},
                dash: {name: 'Dash'},
                zec: {name: 'Zcash'},
                doge: {name: 'Dogecoin'},
                usdt: {name: 'Tether (USD-T)'},
            },
        };
    },
    computed: {
        min() {
            if (this.form.method === 'ym') return (this.form.cash = 100);
            if (this.form.method === 'qiwi') return (this.form.cash = 100);

            return (this.form.cash = 15);
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
        submit() {
            this.error = false;

            axios
                .post(this.route('payment.create'), this.form)
                .then(response => {
                    if (response.status !== 200) return;

                    if (['yoomoney', 'anypay'].includes(this.form.cashbox))
                        location.href = response.data.result.payment_url;
                    else if (this.form.cashbox === 'centapp')
                        location.href = response.data.link_page_url;
                })
                .catch(error => {
                    this.error = true;
                    if (error.response.status === 403) {
                        this.message = 'Введена некорректная сумма.';
                        return;
                    }

                    if (error.response.status === 404) {
                        this.message = 'Выбрана несуществующая касса.';
                        return;
                    }

                    this.message =
                        error.response.data.message ??
                        'Ошибки при выполнении запроса. Попробуйте еще раз';
                });
        },
        isCrypto() {
            return (
                this.form.cashbox === 'anypay' &&
                this.form.method !== 'ym' &&
                this.form.method !== 'qiwi'
            );
        },
    },
};
</script>
