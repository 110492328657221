<template>
    <app-layout :title="server.name">
        <div class="container">
            <section class="top section">
                <div class="section__container">
                    <h3
                        class="text-title text-dark text-center section__h3 text-normal"
                        v-text="server.name" />

                    <div class="server-images">
                        <div
                            v-for="image in parseOrEmpty(server.images)"
                            :key="image.src">
                            <img
                                :src="image.src"
                                :alt="image.alt"
                                class="server-images--image" />
                            <span v-text="image.alt" />
                        </div>
                    </div>

                    <h4 class="server-subtitle desc">Описание сервера</h4>
                    <p class="server-desc" v-html="server.description" />

                    <h4 class="server-subtitle">Список модификаций</h4>
                    <div class="server-ul">
                        <a
                            v-for="link in getMods(server)"
                            :key="link.name"
                            :href="link.link"
                            target="_blank"
                            v-html="link.name" />
                    </div>

                    <h4 class="server-subtitle">Полезные ссылки</h4>
                    <div class="server-ul">
                        <a
                            v-for="link in parseOrEmpty(server.links)"
                            :key="link.name"
                            :href="link.link"
                            target="_blank"
                            v-html="link.name" />
                    </div>
                </div>
            </section>
        </div>
    </app-layout>
</template>

<script>
import {isEmpty, sortBy} from 'lodash';

import AppLayout from '@/Layouts/AppLayout.vue';

export default {
    components: {
        AppLayout,
    },

    props: {
        server: Object,
    },

    methods: {
        parseOrEmpty(val) {
            if (isEmpty(val)) {
                return [];
            }

            return JSON.parse(val);
        },

        getMods(server) {
            return sortBy(
                this.parseOrEmpty(server.mods) ?? [],
                mod => mod.name,
            );
        },
    },
};
</script>
