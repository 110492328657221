<template>
    <modal-component :show="show" @close="close">
        <template #title> Покупка товара </template>
        <template #content>
            <form @submit.prevent="submit()">
                <div class="market__modal">
                    <div class="market__modal--left">
                        <img
                            :src="'/uploads/minecraft/' + product.meta?.texture"
                            alt="" />
                    </div>

                    <div class="market__modal--right">
                        <dl>
                            <dt>
                                <span>Название товара:</span>
                            </dt>
                            <dd>
                                <tooltip-component
                                    :tooltip="'ID: ' + product.item_id">
                                    {{
                                        product.item_title ??
                                        product.meta?.locale
                                    }}
                                </tooltip-component>
                            </dd>
                        </dl>
                        <dl
                            v-if="
                                product.item_desc &&
                                JSON.parse(product.item_desc).length > 0
                            ">
                            <dt>
                                <span>Зачарования</span>
                            </dt>
                            <dd>
                                <span
                                    v-if="
                                        JSON.parse(product.item_desc).length ===
                                        1
                                    "
                                    v-html="
                                        JSON.parse(product.item_desc)[0]
                                    "></span>
                                <tooltip-component
                                    v-else
                                    :tooltip="
                                        JSON.parse(product.item_desc).join(
                                            `\r\n`,
                                        )
                                    "
                                    class="enchantment">
                                    все зачарования
                                </tooltip-component>
                            </dd>
                        </dl>
                        <dl v-if="product.item_max_damage !== 0">
                            <dt>
                                <span>Прочность</span>
                            </dt>
                            <dd>
                                <span
                                    v-html="
                                        Math.abs(
                                            product.item_max_damage -
                                                product.item_damage,
                                        )
                                    "></span>
                                <span>/</span>
                                <span v-html="product.item_max_damage"></span>
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                <span>Цена:</span>
                            </dt>
                            <dd>
                                <span
                                    v-html="
                                        (
                                            product.per_item_price * form.items
                                        ).toFixed(2) + ' ₽'
                                    "></span>
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                <span
                                    >Количество (<b>{{ form.items }}</b>
                                    шт.):</span
                                >
                            </dt>
                            <dd class="count">
                                <button
                                    type="button"
                                    @click.prevent="
                                        form.items <= 1 ? null : form.items--
                                    ">
                                    <svg
                                        fill="none"
                                        height="25"
                                        viewBox="0 0 25 25"
                                        width="25"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M25 12.5C25 19.4036 19.4036 25 12.5 25C5.59644 25 0 19.4036 0 12.5C0 5.59644 5.59644 0 12.5 0C19.4036 0 25 5.59644 25 12.5ZM0.939529 12.5C0.939529 18.8847 6.11533 24.0605 12.5 24.0605C18.8847 24.0605 24.0605 18.8847 24.0605 12.5C24.0605 6.11533 18.8847 0.939529 12.5 0.939529C6.11533 0.939529 0.939529 6.11533 0.939529 12.5Z"
                                            fill="#2C3147" />
                                        <path
                                            d="M15.0391 13.9121H9.91211V11.998H15.0391V13.9121Z"
                                            fill="#2C3147" />
                                    </svg>
                                </button>

                                <input
                                    v-model="form.items"
                                    class="input"
                                    :max="product.item_count"
                                    min="0"
                                    type="range"
                                    @keypress="isNumber($event)" />

                                <button
                                    type="button"
                                    @click.prevent="
                                        form.items > product.item_count - 1
                                            ? null
                                            : form.items++
                                    ">
                                    <svg
                                        fill="none"
                                        height="25"
                                        viewBox="0 0 25 25"
                                        width="25"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M25 12.5C25 19.4036 19.4036 25 12.5 25C5.59644 25 0 19.4036 0 12.5C0 5.59644 5.59644 0 12.5 0C19.4036 0 25 5.59644 25 12.5ZM0.939529 12.5C0.939529 18.8847 6.11533 24.0605 12.5 24.0605C18.8847 24.0605 24.0605 18.8847 24.0605 12.5C24.0605 6.11533 18.8847 0.939529 12.5 0.939529C6.11533 0.939529 0.939529 6.11533 0.939529 12.5Z"
                                            fill="#2C3147" />
                                        <path
                                            d="M17.334 11.207V13.3262H7.58789V11.207H17.334ZM13.6035 7.22266V17.5742H11.3281V7.22266H13.6035Z"
                                            fill="#2C3147" />
                                    </svg>
                                </button>
                            </dd>
                        </dl>
                    </div>
                </div>

                <notify-component
                    v-if="error"
                    :message="message"
                    type="error" />

                <div class="market__modal--button">
                    <button class="button button--blue" type="submit">
                        Купить товар
                    </button>
                </div>
            </form>
        </template>
        <template #close>
            <button class="modal__close" type="button" @click="close()">
                <close-modal-icon />
            </button>
        </template>
    </modal-component>
</template>

<script>
import ModalComponent from '@/Components/Reusable/ModalComponent.vue';
import TooltipComponent from '../Reusable/TooltipComponent.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import JetLabel from '@/Jetstream/Label.vue';
import AlertComponent from '@/Components/Reusable/AlertComponent.vue';
import CloseModalIcon from '@/Components/Reusable/CloseModalIcon.vue';
import NotifyComponent from '@/Components/Reusable/NotifyComponent.vue';
import axios from 'axios';

export default {
    name: 'MarketModalComponent',
    components: {
        NotifyComponent,
        AlertComponent,
        ModalComponent,
        JetButton,
        JetInput,
        JetCheckbox,
        JetLabel,
        CloseModalIcon,
        TooltipComponent,
    },
    props: ['show', 'product'],
    emits: ['close', 'update'],
    data() {
        return {
            form: this.$inertia.form({
                productId: null,
                items: 1,
            }),
            error: false,
            message: '',
        };
    },
    methods: {
        close() {
            this.$emit('close');
        },
        submit() {
            this.error = false;
            this.form.productId = this.product.id;

            axios
                .post(this.route('market.buy'), this.form)
                .then(response => {
                    if (response.data.success !== true) {
                        this.error = true;
                        this.message = response.data.message;
                        this.form.recentlySuccessful = false;
                        return;
                    }

                    this.error = false;
                    this.$emit('update', response.data.message);
                    this.close();
                })
                .catch(error => {
                    this.error = true;
                    this.form.recentlySuccessful = false;
                    this.message =
                        'Ошибки при выполнении запроса. Попробуйте еще раз';
                });
        },
        isNumber(evt) {
            evt = evt ? evt : window.event;
            let charCode = evt.which ? evt.which : evt.keyCode;

            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
    },
};
</script>
