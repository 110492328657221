<template>
    <section class="zombie">
        <div class="container zombie__wrapper">
            <div class="zombie__title">
                <h3 class="text-title text-shop text-normal">
                    Хардкорная сборка Zombie Apocalypse
                </h3>
                <h4 class="text-subtitle text-shop">
                    Новый сервер с уникальными механиками. Орды зомби, ломающие
                    блоки, оружие, медикаменты, радиация, торговля и заброшенные
                    города.
                </h4>
            </div>

            <div class="zombie__container">
                <Link
                    :href="route('market.index')"
                    class="zombie__container--left">
                    <h4>Перейти на рынок сервера Zombie Apocalypse</h4>
                    <p>
                        Уникальная возможность для игроков данного сервера
                        продавать игровые вещи на рынке за донатную валюту.
                        Каждый игрок может подняться на самый верх без доната.
                    </p>

                    <div class="arrow">
                        <svg
                            fill="none"
                            height="50"
                            viewBox="0 0 50 50"
                            width="50"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                clip-rule="evenodd"
                                d="M12.5 24.9998C12.5 24.5854 12.6646 24.1879 12.9576 23.8949C13.2507 23.6019 13.6481 23.4373 14.0625 23.4373H32.1656L25.4562 16.731C25.1629 16.4376 24.998 16.0397 24.998 15.6248C24.998 15.2099 25.1629 14.8119 25.4562 14.5185C25.7496 14.2251 26.1476 14.0603 26.5625 14.0603C26.9774 14.0603 27.3754 14.2251 27.6688 14.5185L37.0438 23.8935C37.1893 24.0387 37.3047 24.2111 37.3835 24.4009C37.4622 24.5908 37.5028 24.7943 37.5028 24.9998C37.5028 25.2053 37.4622 25.4088 37.3835 25.5986C37.3047 25.7885 37.1893 25.9609 37.0438 26.106L27.6688 35.481C27.3754 35.7744 26.9774 35.9393 26.5625 35.9393C26.1476 35.9393 25.7496 35.7744 25.4562 35.481C25.1629 35.1876 24.998 34.7897 24.998 34.3748C24.998 33.9599 25.1629 33.5619 25.4562 33.2685L32.1656 26.5623H14.0625C13.6481 26.5623 13.2507 26.3977 12.9576 26.1046C12.6646 25.8116 12.5 25.4142 12.5 24.9998Z"
                                fill="white"
                                fill-rule="evenodd" />
                        </svg>
                    </div>
                </Link>
                <div class="zombie__container--right">
                    <div class="zombie__info">
                        <span v-html="$page.props.marketItems"></span>
                        <p>
                            {{
                                declOfNum($page.props.marketItems, [
                                    'товар',
                                    'товара',
                                    'товаров',
                                ])
                            }}
                            на рынке
                        </p>
                    </div>
                    <div class="zombie__info">
                        <span>более 1 млн</span>
                        <p>Зомби и враждебных мобов на карте</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {Link} from '@inertiajs/vue3';
import {declOfNum} from '@/Methods/declOfNum';

export default {
    name: 'ZombieApocalypseComponent',
    components: {
        Link,
    },
    methods: {
        declOfNum: declOfNum,
    },
};
</script>

<style scoped></style>
