<template>
    <button
        :type="type"
        class="button--small button--block button button--grey">
        <slot></slot>
    </button>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'button',
        },
    },
};
</script>
