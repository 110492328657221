<template>
    <modal-component :show="show" @close="close">
        <template #title> Восстановление пароля </template>
        <template #content>
            <form
                class="login-form"
                name="forget-form"
                @submit.prevent="submit">
                <div class="input-group">
                    <jet-label for="forget-email" value="Почта" />
                    <jet-input
                        id="forget-email"
                        v-model="form.email"
                        autocomplete
                        class="input-email"
                        required
                        type="email" />
                </div>

                <button
                    :class="{'button--process': form.processing}"
                    :disabled="form.processing"
                    class="button button--blue login-button forget-password--button"
                    type="submit">
                    Восстановить пароль
                </button>
            </form>

            <div v-if="formSubmitted">
                <notify-component
                    message="Ссылка на сброс пароля была отправлена!"
                    type="success"></notify-component>
            </div>
        </template>

        <template #close>
            <button class="modal__close" type="button" @click="close()">
                <close-modal-icon />
            </button>
        </template>
    </modal-component>
</template>

<script>
import ModalComponent from '@/Components/Reusable/ModalComponent.vue';

import JetButton from '@/Jetstream/Button.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import JetLabel from '@/Jetstream/Label.vue';
import NotifyComponent from '@/Components/Reusable/NotifyComponent.vue';
import CloseModalIcon from '@/Components/Reusable/CloseModalIcon.vue';

export default {
    name: 'ForgetPasswordModalComponent',
    components: {
        ModalComponent,
        JetButton,
        JetInput,
        JetCheckbox,
        JetLabel,
        NotifyComponent,
        CloseModalIcon,
    },
    props: ['show', 'status'],
    emits: ['close'],
    data() {
        return {
            formSubmitted: false,
            form: this.$inertia.form({
                email: '',
            }),
        };
    },
    computed: {
        errors() {
            return this.$page.props.errors;
        },

        hasErrors() {
            return Object.keys(this.errors).length > 0;
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
        submit() {
            this.form.post('/forgot-password', {
                // onFinish: () => this.formSubmitted = true,
                onSuccess: () => (this.formSubmitted = true),
            });
        },
    },
};
</script>
