<template>
    <app-layout title="Контакты">
        <div class="container">
            <section class="top section">
                <div class="section__container">
                    <h3 class="text-title text-dark section__h3">Контакты</h3>

                    <div v-html="contacts"></div>
                </div>
            </section>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue';

export default {
    components: {
        AppLayout,
    },
    props: {
        contacts: String,
    },
};
</script>
