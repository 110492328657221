<template>
    <div class="market__tabs">
        <div
            v-if="$page.props.user"
            :class="{active: current === 'cart'}"
            class="market__tabs--item">
            <Link :href="route('market.cart')" preserve-scroll>Корзина</Link>
        </div>
        <div :class="{active: current === 'index'}" class="market__tabs--item">
            <Link :href="route('market.index')" preserve-scroll>Рынок</Link>
        </div>
        <div
            v-if="$page.props.user"
            :class="{active: current === 'my'}"
            class="market__tabs--item">
            <Link :href="route('market.my')" preserve-scroll>Мои товары</Link>
        </div>
        <div
            v-if="$page.props.user"
            :class="{active: current === 'history'}"
            class="market__tabs--item">
            <Link :href="route('market.history')" preserve-scroll
                >История действий</Link
            >
        </div>
    </div>
</template>

<script>
import {Link} from '@inertiajs/vue3';

export default {
    name: 'Menu',
    components: {
        Link,
    },
    props: {
        current: String,
    },
};
</script>

<style scoped></style>
