<template>
    <app-layout title="Профиль">
        <div class="container">
            <section class="cabinet section">
                <div class="section__container">
                    <h3 class="text-title text-dark section__h3">
                        Cкин и плащ
                    </h3>
                    <p class="text-subtitle text-dark section__p">
                        Ниже 2D-модель Вашего персонажа на&nbsp;всех наших
                        игровых серверах. Выберите изображение следующих
                        размеров: от&nbsp;32&times;64 до&nbsp;1024&times;1024.
                        <!--                        Конвертация скинов:-->
                        <!--                        <a target="_blank" href="https://mcskins.top/x64-to-x32"-->
                        <!--                            >mcskins.top/x64-to-x32</a-->
                        <!--                        >-->
                    </p>
                </div>
                <div class="cabinet__skin">
                    <div class="cabinet__skin--left">
                        <form>
                            <img
                                :alt="user.name"
                                :src="
                                    '/skin/' + user.name + '?' + lastUpdated
                                " />
                            <label
                                for="skin"
                                :disabled="skin.disabled || skin.processing">
                                <p
                                    v-text="
                                        getLoadingMessage(skin.skin?.name)
                                    " />
                                <span
                                    >Выбрать скин
                                    <svg
                                        fill="none"
                                        height="14"
                                        viewBox="0 0 12 14"
                                        width="12"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M2 1.07862e-08C1.60218 1.07862e-08 1.22064 0.158035 0.93934 0.43934C0.658035 0.720644 0.5 1.10218 0.5 1.5V12.5C0.5 12.8978 0.658035 13.2794 0.93934 13.5607C1.22064 13.842 1.60218 14 2 14H10C10.3978 14 10.7794 13.842 11.0607 13.5607C11.342 13.2794 11.5 12.8978 11.5 12.5V4.414C11.4998 4.01638 11.3417 3.63512 11.0605 3.354L8.1465 0.439C7.86518 0.157859 7.48372 -4.76506e-05 7.086 1.07862e-08H2ZM1.5 1.5C1.5 1.36739 1.55268 1.24021 1.64645 1.14645C1.74021 1.05268 1.86739 1 2 1H6.5V3.5C6.5 3.89782 6.65804 4.27936 6.93934 4.56066C7.22064 4.84196 7.60218 5 8 5H10.5V12.5C10.5 12.6326 10.4473 12.7598 10.3536 12.8536C10.2598 12.9473 10.1326 13 10 13H2C1.86739 13 1.74021 12.9473 1.64645 12.8536C1.55268 12.7598 1.5 12.6326 1.5 12.5V1.5ZM10.293 4H8C7.86739 4 7.74021 3.94732 7.64645 3.85355C7.55268 3.75979 7.5 3.63261 7.5 3.5V1.207L10.293 4Z"
                                            fill="#008BE1" />
                                    </svg>
                                </span>
                                <input
                                    id="skin"
                                    ref="skin"
                                    accept="image/png"
                                    name="skin"
                                    type="file"
                                    @input="
                                        uploadSkin($event.target.files[0])
                                    " />
                            </label>

                            <notify-component
                                v-if="skin.recentlySuccessful"
                                :message="
                                    skin.skin === null
                                        ? 'Вы успешно удалили скин'
                                        : 'Новый скин успешно установлен'
                                "
                                type="success" />
                            <jet-input-error :message="skin.errors.message" />

                            <div class="cabinet__skin--buttons">
                                <!--<button
                                    :disabled="
                                        skin.disabled ||
                                        skin.processing ||
                                        skin.skin === null
                                    "
                                    class="button button--blue"
                                    type="button"
                                    @click.prevent="uploadSkin()">
                                    загрузить скин
                                </button>-->
                                <button
                                    :disabled="skin.disabled || skin.processing"
                                    class="button button--red"
                                    type="button"
                                    @click.prevent="deleteSkin()">
                                    удалить скин
                                </button>
                            </div>
                        </form>
                    </div>
                    <div class="cabinet__skin--right">
                        <form>
                            <img
                                :alt="user.name"
                                :src="
                                    '/skin/' + user.name + '?' + lastUpdated
                                " />
                            <label
                                for="cloak"
                                :disabled="cloak.disabled || cloak.processing">
                                <p
                                    v-text="
                                        getLoadingMessage(cloak.cloak?.name)
                                    " />
                                <span
                                    >Выбрать плащ
                                    <svg
                                        fill="none"
                                        height="14"
                                        viewBox="0 0 12 14"
                                        width="12"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M2 1.07862e-08C1.60218 1.07862e-08 1.22064 0.158035 0.93934 0.43934C0.658035 0.720644 0.5 1.10218 0.5 1.5V12.5C0.5 12.8978 0.658035 13.2794 0.93934 13.5607C1.22064 13.842 1.60218 14 2 14H10C10.3978 14 10.7794 13.842 11.0607 13.5607C11.342 13.2794 11.5 12.8978 11.5 12.5V4.414C11.4998 4.01638 11.3417 3.63512 11.0605 3.354L8.1465 0.439C7.86518 0.157859 7.48372 -4.76506e-05 7.086 1.07862e-08H2ZM1.5 1.5C1.5 1.36739 1.55268 1.24021 1.64645 1.14645C1.74021 1.05268 1.86739 1 2 1H6.5V3.5C6.5 3.89782 6.65804 4.27936 6.93934 4.56066C7.22064 4.84196 7.60218 5 8 5H10.5V12.5C10.5 12.6326 10.4473 12.7598 10.3536 12.8536C10.2598 12.9473 10.1326 13 10 13H2C1.86739 13 1.74021 12.9473 1.64645 12.8536C1.55268 12.7598 1.5 12.6326 1.5 12.5V1.5ZM10.293 4H8C7.86739 4 7.74021 3.94732 7.64645 3.85355C7.55268 3.75979 7.5 3.63261 7.5 3.5V1.207L10.293 4Z"
                                            fill="#008BE1" />
                                    </svg>
                                </span>
                                <input
                                    id="cloak"
                                    ref="cloak"
                                    accept="image/png"
                                    name="cloak"
                                    type="file"
                                    @input="
                                        uploadCloak($event.target.files[0])
                                    " />
                            </label>

                            <notify-component
                                v-if="cloak.recentlySuccessful"
                                :message="
                                    cloak.cloak === null
                                        ? 'Вы успешно удалили плащ'
                                        : 'Новый плащ успешно установлен'
                                "
                                type="success" />
                            <jet-input-error :message="cloak.errors.message" />

                            <div class="cabinet__skin--buttons">
                                <!--<button
                                    :disabled="
                                        cloak.disabled ||
                                        cloak.processing ||
                                        cloak.cloak === null
                                    "
                                    class="button button--blue"
                                    type="button"
                                    @click.prevent="uploadCloak()">
                                    загрузить плащ
                                </button>-->
                                <button
                                    :disabled="
                                        cloak.disabled || cloak.processing
                                    "
                                    class="button button--red"
                                    type="button"
                                    @click.prevent="deleteCloak()">
                                    удалить плащ
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="cabinet__change">
                    <div class="cabinet__change--left">
                        <form @submit.prevent="updatePassword">
                            <h4 class="cabinet__h4">Сменить пароль</h4>
                            <div class="input-group">
                                <jet-label
                                    for="current_password"
                                    value="Старый пароль" />
                                <jet-input
                                    id="current_password"
                                    ref="current_password"
                                    v-model="form.current_password"
                                    autocomplete="current-password"
                                    class="input-password"
                                    placeholder="Введите старый пароль"
                                    required
                                    type="password" />
                                <jet-input-error
                                    :message="form.errors.current_password" />
                            </div>
                            <div class="input-group">
                                <jet-label
                                    for="password"
                                    value="Новый пароль" />
                                <jet-input
                                    id="password"
                                    ref="password"
                                    v-model="form.password"
                                    autocomplete="new-password"
                                    class="input-password"
                                    placeholder="Введите новый пароль"
                                    required
                                    type="password" />
                                <jet-input-error
                                    :message="form.errors.password" />
                            </div>

                            <div class="input-group">
                                <jet-label
                                    for="password_confirmation"
                                    value="Новый пароль" />
                                <jet-input
                                    id="password_confirmation"
                                    ref="password_confirmation"
                                    v-model="form.password_confirmation"
                                    autocomplete="new-password"
                                    class="input-password"
                                    placeholder="Повторите новый пароль"
                                    required
                                    type="password" />
                                <jet-input-error
                                    :message="
                                        form.errors.password_confirmation
                                    " />
                            </div>

                            <notify-component
                                v-if="form.recentlySuccessful"
                                message="Вы успешно изменили свой пароль"
                                type="success" />

                            <div class="cabinet__change--buttons">
                                <button
                                    :disabled="
                                        form.disabled ||
                                        form.processing ||
                                        form.password_confirmation === null ||
                                        form.password_confirmation === ''
                                    "
                                    class="button button--blue"
                                    type="submit">
                                    изменить пароль
                                </button>
                            </div>
                        </form>
                    </div>
                    <div class="cabinet__change--right">
                        <form @submit.prevent="updateProfileInformation">
                            <h4 class="cabinet__h4">Сменить почту</h4>
                            <div class="input-group">
                                <jet-label for="email" value="Текущая почта" />
                                <jet-input
                                    id="email"
                                    :value="user.email"
                                    autocomplete="email"
                                    class="input-email"
                                    placeholder="Введите адрес текущей почты"
                                    readonly="readonly"
                                    required
                                    type="email" />
                            </div>
                            <div class="input-group">
                                <jet-label
                                    for="email-new"
                                    value="Новая почта" />
                                <jet-input
                                    id="email-new"
                                    v-model="changeEmail.email"
                                    class="input-email"
                                    placeholder="Введите адрес новой почты"
                                    required
                                    type="email" />
                                <jet-input-error
                                    :message="changeEmail.errors.email" />
                            </div>

                            <notify-component
                                v-if="changeEmail.recentlySuccessful"
                                message="Ваш адрес электронной почты был успешно изменен"
                                type="success" />

                            <div class="cabinet__change--buttons">
                                <button
                                    :disabled="
                                        changeEmail.disabled ||
                                        changeEmail.processing ||
                                        changeEmail.email === null ||
                                        changeEmail.email === ''
                                    "
                                    class="button button--blue"
                                    type="submit">
                                    сменить почту
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>

            <section class="cabinet cabinet__refer section">
                <div class="cabinet__container section__container">
                    <h3 class="cabinet__h4">Реферальная система</h3>
                    <refer-component
                        :refers="refers"
                        :referral_link="referral_link" />
                </div>
            </section>

            <section class="cabinet cabinet__two-factor section">
                <div class="cabinet__container section__container">
                    <h3 class="cabinet__h4">Двухфакторная аутентификация</h3>
                    <two-factor-cabinet />
                </div>
            </section>

            <section class="cabinet cabinet__history section">
                <div class="cabinet__container section__container">
                    <h3 class="cabinet__h4">
                        Платные действия игрока {{ user.name }}
                    </h3>

                    <history :actions="actions" />
                </div>
            </section>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetLabel from '@/Jetstream/Label.vue';
import JetInputError from '@/Jetstream/InputError.vue';
import NotifyComponent from '@/Components/Reusable/NotifyComponent.vue';
import moment from 'moment';
import TwoFactorCabinet from './Cabinet/TwoFactor.vue';
import ReferComponent from './Cabinet/ReferComponent.vue';
import History from './Cabinet/History.vue';
import {parseDate} from '@/Methods/parseDate.js';
import axios from 'axios';

export default {
    name: 'Home',
    components: {
        History,
        ReferComponent,
        TwoFactorCabinet,
        NotifyComponent,
        AppLayout,
        JetInput,
        JetLabel,
        JetInputError,
    },

    props: ['user', 'actions', 'refers', 'referral_link'],
    data() {
        return {
            date: moment().format('DD.MM.YYYY HH:mm'),
            lastUpdated: moment().utc(),
            skin: this.$inertia.form({
                skin: null,
                type: 'skin',
                disabled: false,
            }),
            cloak: this.$inertia.form({
                cloak: null,
                type: 'skin',
                disabled: false,
            }),
            form: this.$inertia.form({
                current_password: '',
                password: '',
                password_confirmation: '',
                disabled: false,
            }),
            changeEmail: this.$inertia.form({
                _method: 'PUT',
                name: this.user.name,
                email: null,
                disabled: false,
            }),
        };
    },

    methods: {
        updatePassword() {
            this.form.put(this.route('user-password.update'), {
                errorBag: 'updatePassword',
                preserveScroll: true,
                onSuccess: () => this.form.reset(),
                onBefore: () => {
                    this.form.errors = {};
                    this.form.disabled = true;

                    setTimeout(() => (this.form.disabled = false), 2500);
                },
                onError: () => {
                    if (this.form.errors.password) {
                        this.form.reset('password', 'password_confirmation');
                        this.$refs.password.focus();
                    }

                    if (this.form.errors.current_password) {
                        this.form.reset('current_password');
                        this.$refs.current_password.focus();
                    }
                },
            });
        },

        parseDate: parseDate,
        updateProfileInformation() {
            this.changeEmail.put(
                this.route('user-profile-information.update'),
                {
                    preserveScroll: true,
                    errorBag: 'updateProfileInformation',
                    onBefore: () => {
                        this.changeEmail.errors = {};

                        this.changeEmail.disabled = true;
                        setTimeout(
                            () => (this.changeEmail.disabled = false),
                            2000,
                        );
                    },
                },
            );
        },

        deleteSkin() {
            this.skin.recentlySuccessful = false;
            this.skin.errors = {};
            this.skin.processing = true;

            axios
                .post(this.route('skin.delete'), {type: 'skin'})
                .then(response => {
                    if (!response.data.success) {
                        this.skin.errors.message = response.data.message;
                        return;
                    }

                    this.lastUpdated = moment().unix();
                    this.skin.recentlySuccessful = true;
                })
                .catch(() => {
                    this.skin.errors.message = Lang.get('skin.error.default');
                });

            setTimeout(() => (this.skin.processing = false), 2000);
        },

        deleteCloak() {
            this.cloak.recentlySuccessful = false;
            this.cloak.errors = {};
            this.cloak.processing = true;

            axios
                .post(this.route('skin.delete'), {type: 'cloak'})
                .then(response => {
                    if (!response.data.success) {
                        this.cloak.errors.message = response.data.message;
                        return;
                    }

                    this.lastUpdated = moment().unix();
                    this.cloak.recentlySuccessful = true;
                })
                .catch(error => {
                    this.cloak.errors.message = Lang.get('cloak.error.default');
                });

            setTimeout(() => (this.cloak.processing = false), 2000);
        },

        uploadSkin(file) {
            this.skin.skin = file;

            this.skin.recentlySuccessful = false;
            this.skin.errors = {};
            this.skin.processing = true;
            let formData = new FormData();
            formData.append('file', this.skin.skin);
            formData.append('type', 'skin');

            axios
                .post(this.route('skin.upload'), formData, {
                    headers: {
                        'Content-type': 'multipart/form-data',
                    },
                })
                .then(response => {
                    if (!response.data.success) {
                        this.skin.errors.message = response.data.message;
                        return;
                    }

                    // this.skin.skin = null;
                    // this.$refs.skin.value = '';
                    this.lastUpdated = moment().unix();
                    this.skin.recentlySuccessful = true;
                })
                .catch(() => {
                    this.skin.errors.message =
                        'Произошла ошибка при загрузке скина. Попробуйте еще раз';
                });

            setTimeout(() => (this.skin.processing = false), 2000);
        },

        uploadCloak(file) {
            this.cloak.cloak = file;
            this.cloak.recentlySuccessful = false;
            this.cloak.errors = {};
            this.cloak.processing = true;

            let formData = new FormData();
            formData.append('file', this.cloak.cloak);
            formData.append('type', 'cloak');

            axios
                .post(this.route('skin.upload'), formData, {
                    headers: {
                        'Content-type': 'multipart/form-data',
                    },
                })
                .then(response => {
                    if (!response.data.success) {
                        this.cloak.errors.message = response.data.message;
                        return;
                    }

                    // this.cloak.cloak = null;
                    // this.$refs.cloak.value = '';
                    this.lastUpdated = moment().unix();
                    this.cloak.recentlySuccessful = true;
                })
                .catch(() => {
                    this.cloak.errors.message =
                        'Произошла ошибка при загрузке плаща. Попробуйте еще раз';
                });

            setTimeout(() => (this.cloak.processing = false), 2000);
        },

        getLoadingMessage(file) {
            if (file) {
                return `Файл ${file} загружается...`;
            }

            return `Файл не выбран`;
        },
    },
};
</script>
