<template>
    <modal-component :show="show" @close="close">
        <template #title> Информация о магазине блоков </template>
        <template #content>
            <h3 class="h3 text-info">Новый магазин блоков</h3>
            <p class="text-info">
                Для простоты и удобства покупки внутриигровых предметов за донатную валюту,
                магазин блоков был полностью перенесен в игру. Открыть интерфейс магазина
                можно командой <b>/shop</b>, либо же установить в настройках игры
                кнопку и делать это в 1 нажатие.
            </p>
            <img
                alt="Новый магазин блоков"
                src="/build/images/shop__image-1.webp" />

            <h3 class="h3 text-info mt-16">Покупка не выходя из игры</h3>
            <p class="text-info">
                В новом магазине Вы можете очень легко и быстро переключаться
                между вкладками и искать нужный Вам товар. После покупки предмет
                попадает в корзину, перейти в нее можно либо по нажатию кнопки в
                главном GUI магазина, либо же командой <b>/inventory</b>.
            </p>
            <img
                alt="Покупка не выходя из игры"
                src="/build/images/shop__image-2.webp" />
        </template>
        <template #close>
            <button class="modal__close" type="button" @click="close()">
                <close-modal-icon />
            </button>
        </template>
    </modal-component>
</template>

<script>
import ModalComponent from '@/Components/Reusable/ModalComponent.vue';
import CloseModalIcon from '@/Components/Reusable/CloseModalIcon.vue';

export default {
    name: 'ShopModalComponent',
    components: {
        ModalComponent,
        CloseModalIcon,
    },
    props: ['show'],
    emits: ['close'],
    data() {
        return {};
    },
    methods: {
        close() {
            this.$emit('close');
        },
    },
};
</script>
