<template>
    <app-layout :title="'Профиль ' + player.name">
        <div class="container">
            <section class="section">
                <div class="section__container profile__container">
                    <div class="profile__left">
                        <img :alt="player.name" :src="'/skin/' + player.name" />
                    </div>
                    <div class="profile__right">
                        <h3
                            class="text-title text-dark section__h3"
                            v-html="player.name"></h3>
                        <ul>
                            <li class="text-subtitle text-dark section__p">
                                Аккаунт зарегистрирован:
                                {{ parseDate(player.created_at) }}
                            </li>
                            <li class="text-subtitle text-dark section__p">
                                Последний визит: {{ lastSeen }}
                            </li>
                            <li class="text-subtitle text-dark section__p">
                                <large-tooltip-component
                                    tooltip="Новичок"
                                    placement="bottom-start">
                                    Опыт: {{ player.xp }} XP
                                </large-tooltip-component>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="section__container">
                    <div class="profile__right--achievements">
                        <tooltip-component
                            v-for="achievement in allAchievements"
                            :key="achievement.name"
                            :class="{active: isActive(achievement)}"
                            :tooltip="
                                isActive(achievement)
                                    ? getDate(achievement)
                                    : 'Не разблокировано'
                            "
                            placement="bottom">
                            <img
                                :alt="achievement.name"
                                :src="'/build/svg/' + achievement.icon" />
                            <h4>{{ achievement.name }}</h4>
                            <p v-html="achievement.desc"></p>
                        </tooltip-component>
                    </div>
                </div>
            </section>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import {parseDate} from '@/Methods/parseDate.js';
import TooltipComponent from '@/Components/Reusable/TooltipComponent.vue';
import LargeTooltipComponent from '@/Components/Reusable/LargeTooltipComponent.vue';

export default {
    name: 'Profile',
    components: {
        LargeTooltipComponent,
        TooltipComponent,
        AppLayout,
    },
    props: ['player', 'achievements', 'allAchievements', 'lastSeen'],
    data() {
        return {};
    },
    methods: {
        parseDate: parseDate,
        isActive: function (achievement) {
            return (
                this.achievements.filter(item => item.type === achievement.type)
                    .length > 0
            );
        },
        getDate: function (achievement) {
            if (this.isActive(achievement))
                return this.parseDate(
                    this.achievements.filter(
                        item => item.type === achievement.type,
                    )[0].created_at,
                );

            return 'давным-давно';
        },
    },
};
</script>
