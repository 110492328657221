<template>
    <Index current="history">
        <template #alert>
            <alert-component :rounded="true" type="info">
                На странице транзакции Вы сможете увидеть список удачных
                операций на рынке. Когда в вашем магазине покупают предмет или
                покупка совершается Вами, то на этой странице будет отображена
                соответствующая операция.
            </alert-component>
        </template>

        <template #main>
            <h3 class="text-h2 text-dark">История действий</h3>

            <div class="table-responsive">
                <table class="table table--market table-hover">
                    <thead>
                        <tr>
                            <td class="image"></td>
                            <td>Предмет</td>
                            <td>Кол-во</td>
                            <td>Действие</td>
                            <td>Дата</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(product, index) in items.data" :key="index">
                            <td class="image">
                                <img
                                    :src="
                                        '/uploads/minecraft/' +
                                        product.meta?.texture
                                    "
                                    alt="" />
                            </td>

                            <td>
                                <tooltip-component
                                    :tooltip="'ID: ' + product.item_id">
                                    {{
                                        product.item_title ??
                                        product.meta?.locale
                                    }}
                                </tooltip-component>
                            </td>

                            <td v-html="product.item_count + ' шт.'"></td>
                            <td v-html="product.action"></td>
                            <td v-html="parseDate(product.sell_datetime)"></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <pagination-component
                :count="items.total"
                :links="items.links"
                :market="true" />
        </template>
    </Index>
</template>

<script>
import Index from '@/Pages/Market/Index.vue';
import AlertComponent from '@/Components/Reusable/AlertComponent.vue';
import PaginationComponent from '@/Components/PaginationComponent.vue';
import TooltipComponent from '@/Components/Reusable/TooltipComponent.vue';
import {Link} from '@inertiajs/vue3';
import AppLayout from '@/Layouts/AppLayout.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetLabel from '@/Jetstream/Label.vue';
import NotifyComponent from '@/Components/Reusable/NotifyComponent.vue';
import {parseDate} from '@/Methods/parseDate.js';

export default {
    name: 'Items',
    components: {
        Index,
        AppLayout,
        JetInput,
        JetLabel,
        NotifyComponent,
        AlertComponent,
        PaginationComponent,
        TooltipComponent,
        Link,
    },
    props: {
        items: Object,
    },
    methods: {
        parseDate: parseDate,
    },
};
</script>
