<template>
    <div
        class="alert alert--info cabinet__refer--container"
        @click.prevent="copyURL(referral_link)">
        <div>
            Ваша реферальная ссылка:&nbsp;
            <a
                :href="referral_link"
                class="cabinet__refer--link"
                target="_blank">
                {{ referral_link }}
            </a>
        </div>

        <div>
            <svg
                v-if="success"
                class="image-copy"
                fill="none"
                height="16"
                viewBox="0 0 100 100"
                width="16"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12.598 52.597C12.2042 52.2032 7.24839 47.2477 8.09771 48.0969C12.5703 52.5694 37.0978 77.0969 37.0978 77.0969C37.0978 77.0969 85.6188 28.5755 92.0977 22.0967C92.7734 21.4209 89.6994 24.4956 89.3216 24.8734"
                    stroke="#2C3147"
                    stroke-width="10" />
            </svg>

            <svg
                v-else
                class="image-copy"
                fill="none"
                height="16"
                viewBox="0 0 100 100"
                width="16"
                xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_8_5)">
                    <path
                        clip-rule="evenodd"
                        d="M75.9329 11.3607V17.6514H85.8532H85.8613V17.6676C88.9945 17.6758 91.8346 18.9453 93.8854 21.0042C95.9199 23.0469 97.1895 25.8708 97.1976 28.9958H97.2139V29.012V88.6393V88.6475H97.1976C97.1895 91.7725 95.9199 94.6126 93.861 96.6716C91.8184 98.7061 88.9945 99.9756 85.8695 99.9837V100H85.8532H35.6416H35.6335V99.9837C32.5085 99.9756 29.6602 98.7061 27.6094 96.6471C25.5749 94.6045 24.3053 91.7806 24.2972 88.6556H24.2809V88.6393V75.2848H14.3607H14.3525V75.2686C11.2275 75.2604 8.37923 73.9909 6.32845 71.932C4.29395 69.8893 3.02441 67.0654 3.01628 63.9404H3V63.9242V11.3607V11.3525H3.01628C3.02441 8.2194 4.30208 5.37923 6.35286 3.32845C8.39551 1.29395 11.2194 0.0244141 14.3444 0.016276V0H14.3607H64.5723H64.5804V0.016276C67.7135 0.0244141 70.5537 1.29395 72.6045 3.35286C74.639 5.39551 75.9085 8.2194 75.9167 11.3444H75.9329V11.3607V11.3607ZM67.3229 17.6514V11.3607V11.3444H67.3392C67.3392 10.6038 67.0218 9.92025 66.5173 9.41569C66.0208 8.91927 65.3291 8.60189 64.5885 8.60189V8.61816H64.5804H14.3688H14.3525V8.60189C13.612 8.60189 12.9284 8.91927 12.4238 9.42383C11.9274 9.92025 11.61 10.612 11.61 11.3525H11.6263V11.3607V63.9242V63.9404H11.61C11.61 64.681 11.9274 65.3646 12.432 65.8691C12.9284 66.3656 13.6201 66.6829 14.3607 66.6829V66.6667H14.3688H24.2891V29.012V29.0039H24.3053C24.3135 25.8708 25.5911 23.0306 27.6419 20.9798C29.6846 18.9453 32.5085 17.6758 35.6335 17.6676V17.6514H35.6497H67.3229ZM88.5957 88.6393V29.012V28.9958H88.612C88.612 28.2552 88.2946 27.5716 87.79 27.0671C87.2936 26.5706 86.6019 26.2533 85.8613 26.2533V26.2695H85.8532H35.6416H35.6253V26.2533C34.8848 26.2533 34.2012 26.5706 33.6966 27.0752C33.2002 27.5716 32.8828 28.2633 32.8828 29.0039H32.8991V29.012V88.6393V88.6556H32.8828C32.8828 89.3962 33.2002 90.0798 33.7048 90.5843C34.2012 91.0807 34.8929 91.3981 35.6335 91.3981V91.3818H35.6416H85.8532H85.8695V91.3981C86.61 91.3981 87.2936 91.0807 87.7982 90.5762C88.2946 90.0798 88.612 89.388 88.612 88.6475H88.5957V88.6393V88.6393Z"
                        fill="#2C3147"
                        fill-rule="evenodd" />
                </g>
                <defs>
                    <clipPath id="clip0_8_5">
                        <rect
                            fill="white"
                            height="100"
                            transform="translate(3)"
                            width="94.2139" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    </div>

    <div v-if="refers.length > 0" class="cabinet__refer--heads">
        <Link
            v-for="refer in refers"
            :href="route('profile.view', refer.name)"
            class="link">
            <tooltip-component :tooltip="refer.name" :placement="'bottom'">
                <img :alt="refer.name" :src="'/head/' + refer.name" />
            </tooltip-component>
        </Link>
    </div>

    <notify-component
        v-if="success"
        message="Ссылка скопирована в буфер обмена"
        type="success" />
</template>

<script>
import NotifyComponent from '../../Components/Reusable/NotifyComponent.vue';
import {Link} from '@inertiajs/vue3';
import LargeTooltipComponent from '../../Components/Reusable/LargeTooltipComponent.vue';
import TooltipComponent from '../../Components/Reusable/TooltipComponent.vue';

export default {
    name: 'ReferComponent',
    components: {
        TooltipComponent,
        LargeTooltipComponent,
        NotifyComponent,
        Link,
    },
    props: ['refers', 'referral_link'],
    data() {
        return {
            success: false,
        };
    },
    methods: {
        async copyURL(text) {
            this.success = false;

            try {
                await navigator.clipboard.writeText(text);
                this.success = true;
            } catch ($e) {
                this.success = false;
            }
        },
    },
};
</script>
