<template>
    <div></div>
</template>

<script>
import {useToast} from 'vue-toastification';

export default {
    name: 'NotifyComponent',
    props: ['message', 'type'],

    setup() {
        const toast = useToast();

        return {toast};
    },

    created() {
        this.showNotify();
    },

    methods: {
        showNotify() {
            switch (this.type) {
                case 'error':
                    return this.toast.error(this.message);
                case 'info':
                    return this.toast.info(this.message);
                case 'warning':
                    return this.toast.warning(this.message);
                default:
                    return this.toast.success(this.message);
            }
        },
    },
};
</script>
